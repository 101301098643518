import axios from 'axios'
import dayjs from 'dayjs'
import { API, BASEURL } from './ApiService'

axios.defaults.baseURL = BASEURL

export type AuthData = {
	email?: string | null
	password?: string | null
	ticket?: string | null
}

export const Authenticate = async ({ email, password, ticket }: AuthData) => {
	const status = {
		success: false,
		message: ''
	}

	const data: AuthData = {
		email: email ?? null,
		password: password ?? null,
		ticket: ticket ?? null
	}

	await axios
		.post('v1/authenticate', data, {})
		.then(response => {
			if (response.status === 200) {
				var utc = require('dayjs/plugin/utc')
				dayjs.extend(utc)
				localStorage.setItem('absToken', JSON.stringify(response.data))
				status.success = true
			}
		})
		.catch(error => {
			status.success = false
			status.message = error.response?.data.toString()
		})
	return status
}

export const getUserInfo = async () => {
	return await API({
		url: '/v1/me',
		method: 'get'
	}).then(async response => response.data)
}

export const getUserInfoForMainPage = async () => {
	return await API({
		url: '/v1/me/forMainPage',
		method: 'get'
	}).then(async response => response.data)
}

export const getAppUserPermissions = async (projectId: string) => {
	return await API({
		url: '/v1/me/permissions',
		method: 'get',
		params: { projectId }
	}).then(async response => response.data)
}

export const Logout = async () => {
	const absToken = localStorage.absToken !== 'undefined' ? JSON.parse(localStorage.absToken) : null
	if (absToken !== null) {
		await API({
			url: 'v1/logout',
			method: 'post',
			withCredentials: true
		})
			.then(async response => {
				if (response.status === 200) {
					AppExit()
				}
			})
			.catch(error => {
				AppExit()
			})
	} else {
		AppExit()
	}
}

export const getRedirectUrl = () => {
	const response = axios.get('/v1/autodesk/oauth/authenticate').then(response => response.data)
	return response
}

export const AppExit = () => {
	let keys = Object.keys(localStorage)?.filter(key => key !== 'acceptCookies')
	for (let key of keys) {
		localStorage.removeItem(key)
	}
	window.location.href = '/'
}
