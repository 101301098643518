import { IColumns } from 'components/columnConfig'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { TContractsGuideFilters } from './contracts-guide-schema'

type TState = {
	columns: IColumns[]
	currentPage: number
	editedRowId?: string
	filters: TContractsGuideFilters
	showFilters: boolean
	totalItems: number
}
type TStateActions = {
	setCurrentPage: (page: number) => void
	setEditedRowId: (id: string) => void
	setShowFilters: (state?: boolean) => void
	setFilters: (filters: TContractsGuideFilters) => void
	setTotalItems: (pages: number) => void
	unset: () => void
	unsetEditedRowId: () => void
}

const init = {
	columns: [],
	currentPage: 1,
	editedRowId: undefined,
	filters: {},
	showFilters: false,
	totalItems: 1
}

export const useContractsGuideState = create<TState & TStateActions>()(
	persist(
		(set, get) => ({
			...init,
			setCurrentPage: currentPage => set({ currentPage, editedRowId: undefined }),
			setEditedRowId: editedRowId => set({ editedRowId }),
			setShowFilters: state => {
				const currentState = get().showFilters
				set({ showFilters: state ?? !currentState })
			},
			setFilters: filters => set({ filters, currentPage: 1 }),
			setTotalItems: totalItems => set({ totalItems }),
			unset: () => set({ ...init }),
			unsetEditedRowId: () => set({ editedRowId: undefined })
		}),
		{
			name: 'contractsGuideColumns',
			storage: createJSONStorage(() => localStorage),
			partialize: state => ({ columns: state.columns })
		}
	)
)
