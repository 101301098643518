import { useQuery } from '@tanstack/react-query'
import { issueCardApi } from '..'
import { useIssueCardState } from './issue-card-state'
import { AxiosError } from 'axios'

export const useIssueCard = () => {
	const { issueId, openCard } = useIssueCardState()
	return useQuery({
		queryKey: ['issue', 'card', issueId],
		queryFn: async () => {
			if (issueId) return await issueCardApi.get(issueId).then(data => data)
			return null
		},
		select: data => data,
		onSuccess: () => openCard(),
		onError: (err: AxiosError) => err,
		refetchOnWindowFocus: false,
		cacheTime: 0,
		enabled: !!issueId,
		retry: 0
	})
}

export const useIssueAttachments = () => {
	const { issueId } = useIssueCardState()
	return useQuery({
		queryKey: ['issue', 'attachments', issueId],
		queryFn: async () => {
			if (issueId) return await issueCardApi.getAttachments(issueId).then(data => data)
			return null
		},
		select: data => data,
		refetchOnWindowFocus: false
	})
}
