import { CheckSquareFilled, CloseSquareFilled } from '@ant-design/icons'
import { TableColumnsType } from 'antd'
import dayjs from 'dayjs'
import { TReport } from '../model/reports-schema'

export const reportColumns: TableColumnsType<TReport> = [
	{
		title: 'Код замечания',
		dataIndex: 'issueCode',
		width: 100
	},
	{
		title: 'Описание замечания',
		dataIndex: 'issueDescription',
		width: 300
	},
	{
		title: 'Статус файла',
		dataIndex: 'ordinanceAttachmentStatus',
		width: 100
	},
	{
		title: '№ пункта нормативного акта',
		dataIndex: 'issueActpoint',
		width: 150
	},
	{
		title: 'Описание',
		dataIndex: 'locationDescription',
		width: 100
	},
	{
		title: '№ предписания',
		dataIndex: 'ordinanceNumber',
		width: 150
	},
	{
		title: 'Дата создания',
		dataIndex: 'ordinanceCreatedAt',
		key: 'ordinanceCreatedAt',
		render: value => (value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : ''),
		width: 150
	},
	{
		title: 'Срок устранения',
		dataIndex: 'ordinanceDateTo',
		key: 'ordinanceDateTo',
		render: value => (value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : ''),
		width: 150
	},
	{
		title: 'Генподрядчик',
		dataIndex: 'contractor',
		width: 150
	},
	{
		title: 'Контактное лицо',
		dataIndex: 'contactPerson',
		width: 150
	},
	{
		title: 'Проект',
		dataIndex: 'project',
		width: 150
	},
	{
		title: 'Автор',
		dataIndex: 'ordinanceCreatedBy',
		width: 150
	},
	{
		title: 'Статус',
		dataIndex: 'ordinanceStatus',
		width: 100
	},
	{
		title: 'Дата закрытия предписания',
		dataIndex: 'ordinanceClosedAt',
		render: value => (value && dayjs(value).isValid() ? dayjs(value).format('DD.MM.YYYY') : ''),
		width: 150
	},
	{
		title: 'Акт об устранении',
		dataIndex: 'eliminationAct',
		width: 250
	},
	{
		title: 'Наличие акта об устранении',
		dataIndex: 'isEliminationActSign',
		align: 'center',
		render: value => {
			if (value === true) {
				return <CheckSquareFilled style={{ color: 'green' }} />
			} else if (value === false) {
				return <CloseSquareFilled style={{ color: 'red' }} />
			}
		},
		width: 100
	},
	{
		title: 'Акт о неустранении',
		dataIndex: 'nonEliminationAct',
		width: 200
	},
	{
		title: 'Наличие акта о неустранении',
		dataIndex: 'isNonEliminationActSign',
		align: 'center',
		render: value => {
			if (value === true) {
				return <CheckSquareFilled style={{ color: 'green' }} />
			} else if (value === false) {
				return <CloseSquareFilled style={{ color: 'red' }} />
			}
		},
		width: 100
	},
	{
		title: 'Примечание',
		dataIndex: 'note',
		width: 150
	}
]
