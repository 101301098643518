import { Col, Row, Table } from 'antd'
import { useRatingDashboard } from '../model/diagrams-query'

const ratingColumns = [
	{
		key: 'contractorName',
		title: 'Подрядчик',
		dataIndex: 'contractorName'
	},
	{
		key: 'projectName',
		title: 'Проект',
		dataIndex: 'projectName'
	},
	{
		key: 'countProjects',
		title: 'Количество проектов',
		dataIndex: 'countProjects'
	},
	{
		key: 'countIssues',
		title: 'Замечания',
		dataIndex: 'countIssues'
	},
	{
		key: 'countOrdinances',
		title: 'Предписания',
		dataIndex: 'countOrdinances'
	},
	{
		key: 'countOrdinanceFines',
		title: 'Штрафы',
		dataIndex: 'countOrdinanceFines'
	}
]

export const RatingDashboard = () => {
	const { data: ratingData } = useRatingDashboard()

	return (
		<Row
			wrap={false}
			gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: '#f2f2f2',
				height: '100%'
			}}
		>
			<Col span={24} style={{ padding: '1rem', background: '#fefefe' }}>
				<Table
					rowKey={(record, index) => index!}
					pagination={false}
					className="main-rating-table"
					columns={ratingColumns}
					dataSource={ratingData ?? []}
					size="small"
					bordered
					scroll={{
						x: 700
					}}
				/>
			</Col>
		</Row>
	)
}
