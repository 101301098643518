import { DatabaseOutlined, DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FC, useState } from 'react'
import Reports1C from '..'
import ReportsModal from './reports-modal'

interface IProps {
	download?: boolean
}

export const Reports: FC<IProps> = ({ download }) => {
	const [show, setShow] = useState(false)
	return (
		<>
			<ReportsModal show={show} onClose={() => setShow(false)} />
			<Button
				type={download ? 'default' : 'primary'}
				icon={download ? <DownloadOutlined /> : <DatabaseOutlined />}
				onClick={() => setShow(true)}
			>
				{download ? 'Скачать отчёт' : 'Создать отчёт'}
			</Button>
		</>
	)
}
