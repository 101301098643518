import { Col, Row } from 'antd'
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	Title,
	Tooltip
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { FC } from 'react'
import { Bar } from 'react-chartjs-2'
import { useCountChecklistsDashboard } from '../model/diagrams-query'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)
interface IProps {}

export const ChecklistsDashboard: FC<IProps> = props => {
	const { data: checklistData } = useCountChecklistsDashboard()
	const newStatus = [
		checklistData!?.countCheckListsUkKcNdcNew,
		checklistData!?.countCheckListsUksNdcNew
	]
	const inProgressStatus = [
		checklistData!?.countCheckListsUksNdcInProgress,
		checklistData!?.countCheckListsUkKcInProgress
	]
	const warrantyStatus = [
		checklistData!?.countCheckListsUksNdcWarranty,
		checklistData!?.countCheckListsUkKcWarranty
	]
	const completedStatus = [
		checklistData!?.countCheckListsUksNdcCompleted,
		checklistData!?.countCheckListsUkKcCompleted
	]

	const labels = ['Приемка НДК', 'Приемка УК']
	const datasets = [
		{
			label: 'Новый',
			data: newStatus,
			backgroundColor: '#FFAD14'
		},
		{
			label: 'В работе',
			data: inProgressStatus,
			backgroundColor: '#8099A8'
		},
		{
			label: 'Принято под гарантийные обязательства',
			data: warrantyStatus,
			backgroundColor: '#7A77D9'
		},
		{
			label: 'Завершен',
			data: completedStatus,
			backgroundColor: '#0770C5'
		}
	]

	const datasetsNew: any[] = datasets.map(dataPoint => ({
		...dataPoint
	}))

	datasets[0].data?.forEach((_: any, datasetIndex: any) => {
		const all_i = datasets
			.map((d, dataIndex) => [d.data[datasetIndex], dataIndex])
			.sort(([data1], [data2]) => data1 - data2)
		all_i.forEach(([data, datasetIdx]) => {
			datasetsNew[datasetIdx].data[datasetIndex] = [0, data]
		})
	})

	const data = {
		labels: labels,
		datasets: datasets.map((d, i) => ({
			...d,
			datalabels: {
				color: '#fff',
				anchor: 'center' as const,
				align: 'center' as const,
				formatter: (value: any[]) => (value?.[1]! > 0 ? value![1] : ''),
				font: {
					size: 14
				}
			}
		}))
	}

	const config = {
		type: 'bar',
		data,
		width: '100%',
		height: '80%',
		options: {
			responsive: true,
			plugins: {
				legend: {
					position: 'bottom' as const,
					display: true,
					onClick: (e: any) => null,
					title: {
						display: true,
						color: 'black'
					}
				},
				tooltip: {
					callbacks: {
						label: function (context: any) {
							let label = context.dataset.label || ''

							if (label) {
								label += ': '
							}
							if (context.parsed.y !== null) {
								label += context.parsed.y
							}
							return label
						}
					}
				}
			}
		}
	}

	return (
		<Row
			wrap={false}
			gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: '#f2f2f2',
				height: '100%'
			}}
		>
			<Col span={24} style={{ padding: '1rem', background: '#fefefe' }}>
				<h2>ЧЕК-ЛИСТЫ</h2>
				<Bar {...config} />
			</Col>
		</Row>
	)
}
