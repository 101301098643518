import { useQueryClient } from '@tanstack/react-query'
import { Button, Col, Descriptions, Flex, Modal, Row, Select, message } from 'antd'
import { AxiosError } from 'axios'
import { CopyToClipboard } from 'components/CopyToClipboard'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IProject } from 'interfaces/IEnvironment'
import { FC, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Logout, getAppUserPermissions } from 'services/AuthService'
import {
	getContractors,
	getContractsByProject1CId,
	getIssueSubTypes,
	getIssueTypes
} from 'services/IssuesService'
import { getBuildingPermitsList } from 'services/OrdinanceService'
import { resetAsm } from 'store/asmSlice'
import { resetCheckListsState } from 'store/checklistsSlice'
import {
	setEnvironment,
	setEnvironmentPermissions,
	setEnvironmentProject
} from 'store/environmentSlice'
import { resetIssuesState } from 'store/issueSlice'
import { resetOrdinancesState } from 'store/ordinanceSlice'
import { resetTemplatesState } from 'store/templatesSlice'
import { resetTrafficLight } from 'store/trafficLightSlice'

export interface IErrorData {
	detail: string
	status: number
	title: string
	type: string
}

const { Option } = Select

interface IEnvironmentConfigProps {
	open: boolean
	onClose: () => void
}

const EnvironmentConfig: FC<IEnvironmentConfigProps> = ({ open, onClose }) => {
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { pathname } = useLocation()
	const [isLoading, setIsLoading] = useState(false)
	const { projectsList, project, user } = useAppSelector(state => state.environment)
	const [selectedProject, setSelectedProject] = useState<IProject>(project!)

	const projectChange = (value: string) => {
		const item = projectsList.filter(el => el.id === value)[0]
		setSelectedProject(item)
	}

	const onProjectSelect = async () => {
		if (pathname.includes('unmodel/')) {
			navigate('/unmodel')
		}
		if (pathname.includes('local/')) {
			navigate('/local')
		}
		setIsLoading(true)
		dispatch(resetIssuesState())
		dispatch(resetOrdinancesState())
		dispatch(resetCheckListsState())
		dispatch(resetTemplatesState())
		dispatch(resetAsm())
		dispatch(resetTrafficLight())

		// dispatch(setFilterOrdinance({ currentPage: 1, filters: {} }))

		await getAppUserPermissions(selectedProject.id).then(data => {
			dispatch(setEnvironmentPermissions(data))
		})
		dispatch(
			setEnvironmentProject({
				option: 'project',
				data: selectedProject
			})
		)
		await getContractors().then(data => {
			dispatch(setEnvironment({ state: 'contractors', data }))
		})
		await getIssueTypes()
			.then(data => {
				dispatch(setEnvironment({ state: 'issueTypes', data }))
			})
			.catch((error: AxiosError) => {
				const { detail } = error!?.response!?.data! as IErrorData
				const { url } = error!?.config!
				message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
				return Promise.reject
			})
		await getIssueSubTypes()
			.then(data => {
				dispatch(setEnvironment({ state: 'issueSubTypes', data }))
			})
			.catch((error: AxiosError) => {
				const { detail } = error!?.response!?.data! as IErrorData
				const { url } = error!?.config!
				message.error(`Ошибка при получении данных ${url}: ${detail}`, 5)
				return Promise.reject
			})
		await getContractsByProject1CId(selectedProject!?.project1C?.id!).then(data => {
			dispatch(setEnvironment({ state: 'contracts', data }))
		})
		await getBuildingPermitsList(selectedProject!?.project1C?.id!).then(data => {
			dispatch(setEnvironment({ state: 'buildingPermits', data }))
		})
		queryClient.invalidateQueries({ queryKey: ['issueAnalytics'] })
		queryClient.invalidateQueries({ queryKey: ['ordinanceAnalytics'] })
		queryClient.invalidateQueries({ queryKey: ['violationTypesAnalytics'] })
		queryClient.invalidateQueries({ queryKey: ['useMyFilesAnalytics'] })
		onClose()
	}

	const isForbidden = () => {
		switch (true) {
			case projectsList.length === 0 && user?.contractorId === null:
				return 'Для того, чтобы Вы могли работать, необходимо назначить компанию и проект.'
			case projectsList.length > 0 && user?.contractorId === null:
				return 'Для того, чтобы Вы могли работать, необходимо назначить компанию.'
			default:
				return 'Для того, чтобы Вы могли работать, необходимо назначить проект.'
		}
	}

	const onCloseModal = () => {
		onClose()
	}

	return (
		<Modal
			centered
			width="35vw"
			title={projectsList.length > 0 && user?.contractorId !== null ? 'Выбор проекта' : 'Внимание!'}
			open={open}
			closable={selectedProject && !!selectedProject.id}
			onCancel={onCloseModal}
			destroyOnClose={true}
			styles={{
				body: { width: '100%' }
			}}
			footer={[
				<Row gutter={8} justify="end" align="middle">
					<Col>
						<Button
							onClick={onCloseModal}
							disabled={isLoading}
							type={
								projectsList.length === 0 || user?.contractorId === null ? 'primary' : 'default'
							}
							danger={projectsList.length === 0 || user?.contractorId === null}
						>
							{projectsList.length > 0 && user?.contractorId !== null ? 'Отмена' : 'Выход'}
						</Button>
					</Col>
					{projectsList.length > 0 && user?.contractorId !== null && (
						<Col>
							<Button
								type="primary"
								disabled={(selectedProject && !selectedProject.id) || isLoading}
								onClick={() =>
									onProjectSelect().finally(() => setTimeout(() => setIsLoading(false), 200))
								}
							>
								Выбрать
							</Button>
						</Col>
					)}
				</Row>
			]}
		>
			{projectsList.length > 0 && user?.contractorId !== null ? (
				<Descriptions
					layout="horizontal"
					size="small"
					column={12}
					bordered
					className="at-descriptions"
					labelStyle={{
						fontWeight: 'bold',
						width: '20%',
						alignItems: 'center',
						padding: '2px 8px'
					}}
					contentStyle={{
						width: '80%',
						padding: '0px'
					}}
				>
					<Descriptions.Item label="Проект:" span={12}>
						<Flex gap={4} align="center">
							<Select
								defaultValue={project ? project.name : 'Выберите проект'}
								onChange={projectChange}
								showSearch
								optionFilterProp="children"
								style={{ width: '100%' }}
							>
								{[...projectsList!]!
									.sort((a, b) => a.name.localeCompare(b.name))
									.map((item, index) => (
										<Option key={index} value={item.id}>
											{item.name}
										</Option>
									))}
							</Select>
							<CopyToClipboard
								type="text"
								text={selectedProject?.name!}
								tooltip="Скопировать название проекта"
							/>
						</Flex>
					</Descriptions.Item>
				</Descriptions>
			) : (
				<div style={{ color: '#f30', textAlign: 'center', width: '100%' }}>
					{isForbidden()} <br />
					Обратитесь к Администратору.
				</div>
			)}
		</Modal>
	)
}

export default EnvironmentConfig
