import { useAppSelector } from 'hooks/appReduxHook';
import { IBase } from 'interfaces/IBase';
import { IUserPermission } from 'interfaces/IUser';
import { useCallback } from 'react';

export const useCheckPermissions = () => {
	const { user, permissions, project } = useAppSelector(state => state.environment)
    const userPermissions: IUserPermission[] | null = project === null ? user?.projects!?.flatMap(p => p?.permissions!) : permissions

	return useCallback(
		(permits: string[], every?: boolean) => {
			if (user?.isAdmin) return true
			else {
				return userPermissions
					? every
						? permits.every(check => typeof userPermissions?.find(p => p?.name === check) !== 'undefined')
						: permits.some(check => typeof userPermissions?.find(p => p?.name === check) !== 'undefined')
					: false
			}
		},
		[user,permissions]
	)
}
