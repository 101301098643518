import { IPagination } from "interfaces/IPagination"
import { API } from "services/ApiService"
import { IWbsTaskRole, IWbsTaskStatusDto, IWbsTaskStatusDtoRegistry, IWbsTaskStatusRegistry, IWbsTaskVisa, IWbsTaskVisaDto } from "../model/wbs-task-status-interface"

export const WbsTaskStatusApi = {
	async getRegistry() {
		return await API<IPagination<IWbsTaskStatusDtoRegistry[]>>({
			url: `/v1/wbsTask/status`,
			method: 'get'
		}).then(response => {
			const registry: IPagination<IWbsTaskStatusRegistry[]> = {
				data: response.data.data.map(item => ({
					id: item.id,
					name: item.name,
					description: item.description,
					color: item.color,
					isDeleted: item.isDeleted,
					order: item.order,
					children: item.visas.map(v => ({
						...v,
                        parentId: item?.id!
					}))
				})),
				totalItems: response.data.totalItems,
				totalPages: response.data.totalPages
			}
			return registry
		})
	},
	async updateStatus(id: string, dto: IWbsTaskStatusDto) {
		return await API({
			url: `/v1/wbsTask/status/${id}`,
			method: 'patch',
			data: dto
		}).then(response => response.data)
	},
	async getVisaRegistry(ExceptVisasWithContainerStatusId?: string) {
		return await API<IPagination<IWbsTaskVisa[]>>({
			url: `/v1/visa`,
			method: 'get',
			params: { ExceptVisasWithContainerStatusId }
		}).then(response => response.data)
	},
	async addVisa(dto: IWbsTaskVisaDto) {
		return await API<string>({
			url: `/v1/visa`,
			method: 'post',
			data: dto
		}).then(response => response.data)
	},
	async updateVisa(visaId: string, dto: IWbsTaskVisaDto) {
		return await API<string>({
			url: `/v1/visa/${visaId}`,
			method: 'patch',
			data: dto
		}).then(response => response.data)
	},
	async removeVisa(visaId: string) {
		return await API({
			url: `/v1/visa/${visaId}`,
			method: 'delete'
		}).then(response => response.status)
	},
	async getRoles() {
		return await API<IWbsTaskRole[]>({
			url: `/v1/roles`,
			method: 'get',
			// params: { PermissionsId: [45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 56, 57] }
		}).then(response => response.data)
	}
}