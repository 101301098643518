import { CellContext, Row } from '@tanstack/react-table'
import { InputNumber, Tooltip } from 'antd'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useRef, useState } from 'react'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { RoundedNumber } from 'widgets/estimate/model/estimate-helper'
import { useShallow } from 'zustand/react/shallow'
import { IAdjustmentPosition, IAdjustmentPositionMaterial, IAdjustmentSection, TCellType } from '..'
import { useAdjustmentState } from '../model/adjustment-state'

interface IProps {
	record:
		| CellContext<IAdjustmentPosition | IAdjustmentPositionMaterial, any>
		| CellContext<IAdjustmentPosition, any>
		| CellContext<IAdjustmentPositionMaterial, any>
	cellType: TCellType
	parents: Row<IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial>[]
}

export const AdjustmentCell: FC<IProps> = ({ record, cellType, parents }) => {
	const { user } = useAppSelector(state => state.environment)
	const checkPermissions = useCheckPermissions()
	const inputRef = useRef<HTMLInputElement>(null)
	const [focused, setFocused] = useState(false)

	const { container, onSavePosition } = useAdjustmentState(
		useShallow(state => ({
			container: state.container,
			onSavePosition: state.onSavePosition
		}))
	)
	const initialValue = RoundedNumber(record.cell.getValue()) as string
	const [cellValue, setCellValue] = useState<number | null>(
		+initialValue.replace(/,/, '.').replace(/\s+/g, '')
	)
	const [hasError, setHasError] = useState<string | undefined>()

	const isAuthor =
		(user?.isAdmin ||
			container?.author.id === user?.id ||
			container?.redactors.some(r => r.id === user?.id)) ??
		false

	const canEdit =
		record.row.original.isClosed === false &&
		record.row.original.isEditable === true &&
		('workName' in record.row.original ||
			('materialName' in record.row.original && record.row.original.isExpandable)) &&
		container?.isLocalEstimate === false

	const validateCell = (value: any) => {
		// if (
		// 	cellType === 'amountSecond' &&
		// 	value < record.row.original.confirmedVolumeAmount &&
		// 	container?.isLocalEstimate === false
		// ) {
		// 	setHasError('Значение должно быть >= Выполнено в ПО')
		// } else
		if (
			(cellType === 'amountSecond' || cellType === 'amountSdu') &&
			(value === null || value === 0 || value === undefined)
		) {
			setHasError('Кол-во не может быть пустым или равным 0')
		} else if (value === null) setHasError('Кол-во не может быть пустым')
		else if (Number.isNaN(value)) {
			setHasError('Может содержать только цифры')
		} else {
			setHasError(undefined)
		}
	}
	useEffect(() => {
		validateCell(cellValue)
	}, [cellValue])

	const onSave = (
		cellType: TCellType,
		record:
			| CellContext<IAdjustmentPosition | IAdjustmentPositionMaterial, any>
			| CellContext<IAdjustmentPosition, any>
			| CellContext<IAdjustmentPositionMaterial, any>,
		parents: Row<IAdjustmentSection | IAdjustmentPosition | IAdjustmentPositionMaterial>[]
	) => {
		// const amountError =
		// 	cellType === 'amountSecond' &&
		// 	cellValue !== null &&
		// 	cellValue < record.row.original.confirmedVolumeAmount &&
		// 	container?.isLocalEstimate === false

		if (
			!hasError &&
			//  !amountError &&
			cellValue !== null
		) {
			onSavePosition(
				cellValue,
				cellType,
				record,
				parents,
				checkPermissions([PERMISSIONS.AdjustmentChangePrice])
					? 'AdjustmentChangePrice'
					: 'AdjustmentChangePriceNominated'
			)
		} else {
			setCellValue(+initialValue.replace(/,/, '.').replace(/\s+/g, ''))
			setTimeout(() => {
				setHasError(undefined)
			}, 2000)
		}
	}

	const checkEditable = () => {
		switch (cellType) {
			case 'amountSecond':
				return (
					!container?.ready &&
					(user?.isAdmin ||
						((container?.status.name === CONTAINER_STATUS.new ||
							container?.status.name === CONTAINER_STATUS.preparation) &&
							checkPermissions([PERMISSIONS.AdjustmentCreate]) &&
							isAuthor &&
							(typeof container.isLocalEstimate === 'undefined' || canEdit)))
				)
			case 'amountSdu':
				return (
					!container?.ready &&
					(user?.isAdmin ||
						((container?.status.name === CONTAINER_STATUS.new ||
							container?.status.name === CONTAINER_STATUS.preparation) &&
							checkPermissions([PERMISSIONS.AdjustmentCreate]) &&
							isAuthor &&
							typeof container.isLocalEstimate === 'undefined'))
				)
			case 'priceServiceSecond':
				return (
					!container?.ready &&
					(typeof container?.isLocalEstimate === 'undefined' ||
						(container?.isLocalEstimate === false &&
							record.row.original.isClosed === false &&
							record.row.original.confirmedVolumeAmount <= 0)) &&
					(user?.isAdmin ||
						(container?.status.name === CONTAINER_STATUS.priceApprovalOuter &&
							checkPermissions([PERMISSIONS.AdjustmentExternal]) &&
							(typeof container.isLocalEstimate === 'undefined' ||
								(container?.isLocalEstimate === false &&
									record.row.original.confirmedVolumeAmount <= 0))))
				)
			case 'priceMaterialSecond':
				return (
					!container?.ready &&
					!record.row.original.isFixedPriceMaterial &&
					(typeof container?.isLocalEstimate === 'undefined' ||
						(container?.isLocalEstimate === false &&
							record.row.original.isClosed === false &&
							record.row.original.confirmedVolumeAmount <= 0)) &&
					(user?.isAdmin ||
						(container?.status.name === CONTAINER_STATUS.priceApprovalOuter &&
							checkPermissions([PERMISSIONS.AdjustmentExternal]) &&
							(typeof container.isLocalEstimate === 'undefined' ||
								(container?.isLocalEstimate === false &&
									record.row.original.confirmedVolumeAmount <= 0)) &&
							!record.row.original?.isNominatedSecond))
				)

			case 'priceServiceSdu':
				return (
					!container?.ready &&
					(typeof container?.isLocalEstimate === 'undefined' ||
						(container?.isLocalEstimate === false &&
							record.row.original.isClosed === false &&
							record.row.original.confirmedVolumeAmount <= 0)) &&
					(user?.isAdmin ||
						(container?.status.name === CONTAINER_STATUS.priceApproval &&
							checkPermissions([PERMISSIONS.AdjustmentChangePrice]) &&
							(typeof container?.isLocalEstimate === 'undefined' ||
								(container?.isLocalEstimate === false &&
									record.row.original.confirmedVolumeAmount <= 0))))
				)
			case 'priceMaterialSdu':
				return (
					!container?.ready &&
					!record.row.original.isFixedPriceMaterial &&
					(typeof container?.isLocalEstimate === 'undefined' ||
						(container?.isLocalEstimate === false &&
							record.row.original.isClosed === false &&
							record.row.original.confirmedVolumeAmount <= 0)) &&
					(user?.isAdmin ||
						(container?.status.name === CONTAINER_STATUS.priceApproval &&
							((checkPermissions([PERMISSIONS.AdjustmentChangePrice]) &&
								'isNominatedSdu' in record.row.original &&
								record.row.original?.isNominatedSdu === false) ||
								(checkPermissions([PERMISSIONS.AdjustmentChangePriceNominated]) &&
									'isNominatedSdu' in record.row.original &&
									record.row.original?.isNominatedSdu === true) ||
								('workName' in record.row.original &&
									record.row.original.workName &&
									record.row.original.children.length === 0 &&
									checkPermissions([PERMISSIONS.AdjustmentChangePrice]))) &&
							(typeof container?.isLocalEstimate === 'undefined' ||
								(container?.isLocalEstimate === false &&
									record.row.original.confirmedVolumeAmount <= 0))))
				)
		}
	}

	return !container?.ready &&
		checkEditable() &&
		((record.row.original.isClosed === false &&
			typeof container?.isLocalEstimate === 'undefined') ||
			container?.isLocalEstimate === false) ? (
		<Tooltip color="volcano" placement="left" title={hasError} open={!!hasError && focused}>
			<InputNumber
				ref={inputRef}
				value={cellValue}
				keyboard={false}
				onKeyDown={event => {
					if (
						!/[0-9]|Backspace|Delete|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|\.|\,/.test(event.key)
					)
						event.preventDefault()
				}}
				controls={false}
				changeOnWheel={false}
				decimalSeparator=","
				className="estimate-position-input"
				onChange={setCellValue}
				onFocus={() => {
					setFocused(true)
					if (Number(initialValue) === 0) inputRef.current?.select()
				}}
				onBlur={e => {
					setFocused(false)
					if (initialValue! !== e.currentTarget.value) onSave(cellType, record, parents)
				}}
				onPressEnter={() => inputRef.current?.blur()}
			/>
		</Tooltip>
	) : (
		<>{initialValue}</>
	)
}
