import { API } from 'services/ApiService'
import {
	TContractGuideCatalog,
	TContractsGuideFilters,
	TContractsGuideForm
} from '../model/contracts-guide-schema'
import { IContractor } from 'interfaces/IEnvironment'
import { TBase } from 'shared/schemas'

export const contractsGuideApi = {
	async getRegistry({
		currentPage,
		filters
	}: {
		currentPage: number
		filters?: TContractsGuideFilters
	}) {
		return await API<TContractGuideCatalog>({
			url: `/v1/contracts/catalog`,
			method: 'get',
			params: { offset: (currentPage - 1) * 20, ...filters }
		}).then(response => response.data)
	},

	async updateOrdinanceFine(id: string, dto: TContractsGuideForm) {
		return await API({
			url: `/v1/contracts/${id}/AddOrdinanceFine`,
			method: 'patch',
			data: dto
		}).then(response => response.data)
	},

	async getContractors() {
		return await API<IContractor[]>({
			url: `/v1/contractors`,
			method: 'get'
		}).then(response => response.data)
	},

	async getSubContractors(contractorId: string) {
		return await API<IContractor[]>({
			url: `/v1/contractors/subContractors`,
			method: 'get',
			params: { contractorId }
		}).then(response => response.data)
	},

	async getProjects() {
		return await API<TBase[]>({
			url: `/v1/diagrams/projects`,
			method: 'get'
		}).then(response => response.data)
	},

	async getProjects1c() {
		return await API<TBase[]>({
			url: `/v1/diagrams/projects1c`,
			method: 'get'
		}).then(response => response.data)
	},

	async getConstructionObjects() {
		return await API<TBase[]>({
			url: `/v1/constructionObjects`,
			method: 'get'
		}).then(response => response.data)
	}
}
