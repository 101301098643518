import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'hooks/appReduxHook'
import { getAllContracts } from 'services/ContractsService'
import { getAuthors } from 'services/OrdinanceService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { ordinanceFineApi } from '../api/fine-api'
import { useOrdinanceFineState } from './fine-state'

export const useOrdinanceFine = () => {
	const checkPermissions = useCheckPermissions()
	const { project } = useAppSelector(state => state.environment)
	const { filters, currentPage, setTotalItems, setTotalPages } = useOrdinanceFineState()
	const { user } = useAppSelector(state => state.environment)
	const allCompanies = checkPermissions([PERMISSIONS.AllCompanies])
	return useQuery({
		queryKey: ['ordinanceFine', project?.id, filters, currentPage],
		queryFn: async () => {
			const ordinanceFineAll = await ordinanceFineApi
				.get({
					filters: {
						...filters,
						contractorId: allCompanies ? filters.contractorId : user?.contractorId!
					},
					offset: currentPage === 1 ? 0 : currentPage * 50 - 50
				})
				.then(data => data.data)
			setTotalItems(ordinanceFineAll!.totalItems)
			setTotalPages(ordinanceFineAll!.totalPages)
			return ordinanceFineAll.data
		},
		refetchOnWindowFocus: false
	})
}

export const useOrdinanceFineOne = (id: string) => {
	const { project } = useAppSelector(state => state.environment)
	return useQuery({
		queryKey: ['ordinanceFineOne', project?.id],
		queryFn: async () => {
			return await ordinanceFineApi.getOne(id).then(data => data)
		},
		refetchOnWindowFocus: false
	})
}

export const useOrdinanceFineStatuses = () => {
	return useQuery({
		queryKey: ['ordinanceFineStatuses'],
		queryFn: async () => {
			return await ordinanceFineApi.getStatuses().then(data => data)
		},
		refetchOnWindowFocus: false
	})
}

export const useOrdinanceFineAttachment = (id: string) => {
	return useQuery({
		queryKey: ['ordinanceFineAttachment'],
		queryFn: async () => {
			return await ordinanceFineApi.attachment(id).then(data => data)
		},
		refetchOnWindowFocus: false
	})
}

export const useAuthors = () => {
	const { project } = useAppSelector(state => state.environment)
	return useQuery({
		queryKey: ['authorsFine'],
		queryFn: async () => {
			return await getAuthors(project?.id!).then(data => data)
		}
	})
}

export const useContracts = () => {
	return useQuery({
		queryKey: ['contractsFine'],
		queryFn: async () => {
			return await getAllContracts().then(data => data)
		}
	})
}
