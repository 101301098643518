import {
	BranchesOutlined,
	DeleteOutlined,
	EditOutlined,
	ForkOutlined,
	PartitionOutlined,
	PlusSquareOutlined,
	SaveOutlined,
	StopOutlined
} from '@ant-design/icons'
import { Button, Col, Dropdown, MenuProps, Row, Tooltip } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import { FormInstance } from 'antd/lib'
import { useAppSelector } from 'hooks/appReduxHook'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import { FC, useEffect, useRef, useState } from 'react'
import { CONTAINER_STATUS, PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { useEstimateRelatedDocumentsState } from 'widgets/estimate-creation-from-vor/model/estimate-related-documents-state'
import { EstimateImportData } from 'widgets/estimate-import-data'
import { WBS_TASK_TYPE } from 'widgets/wbs-task-card/model/wbs-task-card-mock'
import { WbsAndLocalActionsImportExport } from 'widgets/wbs/ui/WbsAndLocalActionsImportExport'
import { useShallow } from 'zustand/react/shallow'
import { ILocalEstimate, useLocalEstimateRegistryState } from '..'

interface IProps {
	record: ILocalEstimate
	editable: boolean
	isLoading: boolean
	onSave: (record: ILocalEstimate) => void
	data?: ILocalEstimate[] | null
	localEstimateRegistryForm: FormInstance
}

export const LocalEstimateRegistryActions: FC<IProps> = params => {
	const { record, editable, isLoading, data, onSave, localEstimateRegistryForm } = params
	const [hasErrors, setHasErrors] = useState(false)
	const allVal = useWatch(values => values, localEstimateRegistryForm)
	const checkPermissions = useCheckPermissions()
	const canCreateWbsTask = checkPermissions([PERMISSIONS.WbsTasksCreate])
	const { initForm, openCard, setInitForm } = useWbsTaskState()
	const uploadRef = useRef<any>(null)

	const {
		editableRow,
		setEditableRow,
		setCorrectionRecord,
		setAdjustmentRecord,
		setContainerToRemove,
		setTypeCorrection
	} = useLocalEstimateRegistryState()

	const { user } = useAppSelector(state => state.environment)

	const isAuthor =
		(record?.author?.id === user?.id || record?.redactors.some(r => r.id === user?.id)) ?? false

	const hasAdjustment = () => {
		const res =
			record.mainParent === null ? record : data?.find(d => d.id === record.mainParent?.id)
		return !!res?.children.filter(
			c => c.adjustmentId !== null && c.status?.name !== CONTAINER_STATUS.approved
		).length
	}

	const { toggleShowModal, setSelectedId } = useEstimateRelatedDocumentsState(
		useShallow(state => ({
			toggleShowModal: state.toggleShowModal,
			setSelectedId: state.setSelectedId
		}))
	)

	const canCreateAdjustmentEstimate = () => {
		const res =
			record.mainParent === null ? record : data?.find(d => d.id === record.mainParent?.id)
		return record.mainParent === null
			? !res?.children?.find(c => c.adjustmentId !== null)
			: !res?.children?.filter(c => c.adjustmentId !== null).find(r => r.parent?.id === record.id)
	}

	const canCreateCorrectionEstimate = () => {
		const res =
			record.mainParent === null ? record : data?.find(d => d.id === record.mainParent?.id)
		return record.mainParent === null
			? !res?.children?.find(c => c.adjustmentId === null)
			: !res?.children?.find(r => r.parent?.id === record.id && r.adjustmentId === null)
	}

	const canCreateAdjustment: boolean =
		canCreateAdjustmentEstimate() &&
		record.status?.name === 'Approved' &&
		checkPermissions([PERMISSIONS.AdjustmentCreate]) &&
		record.adjustmentId === null

	const itemsEstimates: MenuProps['items'] = [
		{
			key: 'correction',
			label: `Корректировка`,
			type: 'group',
			children: [
				{
					key: 'createCorrection',
					label: (
						<Tooltip
							zIndex={999999999}
							placement="left"
							title={
								(record.author?.id === user?.id ||
									record.redactors?.some(r => r.id === user?.id)) &&
								checkPermissions([PERMISSIONS.LocalEstimateCreate]) &&
								record.status?.name !== 'Approved'
									? record.adjustmentId
										? 'Создать Корректировку Локальной сметы можно только из документа "Было/Стало" в статусе «Согласован»'
										: 'Создать Корректировку Локальной сметы можно только из КЛС в статусе «Завершена оценка НМТР. Локальная смета отправлена в ЕИС»'
									: ''
							}
						>
							Создать Корректировку Локальной сметы
						</Tooltip>
					),
					icon: <BranchesOutlined />,
					disabled:
						canCreateCorrectionEstimate() === false ||
						(!user?.isAdmin &&
							(!(
								record.status?.name === 'Approved' &&
								((record.mainParent !== null &&
									(record.author?.id === user?.id ||
										record.redactors?.some(r => r.id === user?.id))) ||
									record.mainParent === null)
							) ||
								!checkPermissions([PERMISSIONS.LocalEstimateCreate]))) ||
						// record.children?.length >= 1 ||
						(record.status?.name !== 'Approved' && record.mainParent !== null),
					onClick: () => {
						setCorrectionRecord(record)
						setTypeCorrection('basis')
					}
				},
				{
					key: 'createAdjustment',
					label: 'Создать документ "Было/Стало"',
					icon: <PartitionOutlined />,
					disabled: !canCreateAdjustment,
					onClick: () => setAdjustmentRecord(record)
				},
				{
					key: 'createWbsTask',
					label: 'Создать заявку',
					icon: <BranchesOutlined />,
					disabled:
						!canCreateWbsTask ||
						record.status?.name !== CONTAINER_STATUS.approved ||
						!canCreateAdjustmentEstimate(),
					onClick: () => {
						openCard('local')
						setInitForm({
							...initForm,
							wbsTaskTypeId: WBS_TASK_TYPE.estimateAdjustment.id,
							constructionObjectId: record!?.constructionObject!?.id! ?? undefined,
							typeSectionId:
								record.typeSections?.id &&
								record.typeSections?.id !== '00000000-0000-0000-0000-000000000000'
									? record.typeSections?.id
									: undefined,
							contractId: record.contract?.id ?? undefined,
							estimateId:
								record!?.constructionObject!?.id! && record.typeSections?.id && record.contract?.id
									? record.id
									: undefined,
							estimateLabel:
								record!?.constructionObject!?.id! && record.typeSections?.id && record.contract?.id
									? record.name
									: undefined
						})
					}
				},
				{
					key: 'relatedDocuments',
					label: 'Связанные документы',
					icon: <ForkOutlined />,
					onClick: () => {
						setSelectedId(record.id!)
						toggleShowModal(true)
					}
				}
			]
		}
	]

	const correctionLocal = [
		{
			key: 'createCorrection',
			label: (
				<Tooltip
					zIndex={999999999}
					placement="left"
					title={
						(record.author?.id === user?.id || record.redactors?.some(r => r.id === user?.id)) &&
						checkPermissions([PERMISSIONS.LocalEstimateCreate]) &&
						record.status?.name !== 'Approved'
							? record.adjustmentId
								? 'Создать Корректировку Локальной сметы можно только из документа "Было/Стало" в статусе «Согласован»'
								: 'Создать Корректировку Локальной сметы можно только из КЛС в статусе «Завершена оценка НМТР. Локальная смета отправлена в ЕИС»'
							: ''
					}
				>
					Создать Корректировку Локальной сметы
				</Tooltip>
			),
			icon: <BranchesOutlined />,
			disabled:
				canCreateCorrectionEstimate() === false ||
				(!user?.isAdmin &&
					(!(
						record.status?.name === 'Approved' &&
						((record.mainParent !== null &&
							(record.author?.id === user?.id || record.redactors?.some(r => r.id === user?.id))) ||
							record.mainParent === null)
					) ||
						!checkPermissions([PERMISSIONS.LocalEstimateCreate]))) ||
				// record.children?.length >= 1 ||
				(record.status?.name !== 'Approved' && record.mainParent !== null),
			onClick: () => {
				setCorrectionRecord(record)
				setTypeCorrection('basis')
			}
		},
		{
			key: 'createAdjustment',
			label: 'Создать документ "Было/Стало"',
			icon: <PartitionOutlined />,
			disabled: !canCreateAdjustment,
			onClick: () => setAdjustmentRecord(record)
		},
		{
			key: 'createWbsTask',
			label: 'Создать заявку',
			icon: <BranchesOutlined />,
			disabled:
				!canCreateWbsTask ||
				record.status?.name !== CONTAINER_STATUS.approved ||
				!canCreateAdjustmentEstimate(),
			onClick: () => {
				openCard('local')
				setInitForm({
					...initForm,
					wbsTaskTypeId: WBS_TASK_TYPE.estimateAdjustment.id,
					constructionObjectId: record!?.constructionObject!?.id! ?? undefined,
					typeSectionId:
						record.typeSections?.id &&
						record.typeSections?.id !== '00000000-0000-0000-0000-000000000000'
							? record.typeSections?.id
							: undefined,
					contractId: record.contract?.id ?? undefined,
					estimateId:
						record!?.constructionObject!?.id! && record.typeSections?.id && record.contract?.id
							? record.id
							: undefined,
					estimateLabel:
						record!?.constructionObject!?.id! && record.typeSections?.id && record.contract?.id
							? record.name
							: undefined
				})
			}
		}
	]

	const correctionAdjustment = [
		{
			key: 'createCorrectionSdu',
			label: (
				<Tooltip
					zIndex={999999999}
					placement="left"
					title={
						(record.author?.id === user?.id || record.redactors?.some(r => r.id === user?.id)) &&
						checkPermissions([PERMISSIONS.LocalEstimateCreate]) &&
						record.status?.name !== 'Approved'
							? 'Создать Корректировку Локальной сметы можно только из документа "Было/Стало" в статусе «Согласован»'
							: ''
					}
				>
					Создать Корректировку Локальной сметы по версии СДУ
				</Tooltip>
			),
			icon: <BranchesOutlined />,
			disabled:
				canCreateCorrectionEstimate() === false ||
				(!user?.isAdmin &&
					(!(
						(record.status?.name === 'Approved')
						// &&
						// (record.mainParent !== null &&
						// (record.author?.id === user?.id || record.redactors?.some(r => r.id === user?.id))) ||
						// record.mainParent === null
					) ||
						!checkPermissions([PERMISSIONS.AdjustmentEstimateCorrectionCreate]))) ||
				(record.status?.name !== 'Approved' && record.mainParent !== null),
			onClick: () => {
				setCorrectionRecord(record)
				setTypeCorrection('sdu')
			}
		},
		{
			key: 'createCorrectionContractor',
			label: (
				<Tooltip
					zIndex={999999999}
					placement="left"
					title={
						(record.author?.id === user?.id || record.redactors?.some(r => r.id === user?.id)) &&
						checkPermissions([PERMISSIONS.LocalEstimateCreate]) &&
						record.status?.name !== 'Approved'
							? 'Создать Корректировку Локальной сметы можно только из документа "Было/Стало" в статусе «Согласован»'
							: ''
					}
				>
					Создать Корректировку Локальной сметы по версии Подрядчика
				</Tooltip>
			),
			icon: <BranchesOutlined />,
			disabled:
				canCreateCorrectionEstimate() === false ||
				(!user?.isAdmin &&
					(!(
						(record.status?.name === 'Approved')
						// &&
						// (record.mainParent !== null &&
						// (record.author?.id === user?.id || record.redactors?.some(r => r.id === user?.id))) ||
						// record.mainParent === null
					) ||
						!checkPermissions([PERMISSIONS.AdjustmentEstimateCorrectionCreate]))) ||
				(record.status?.name !== 'Approved' && record.mainParent !== null),
			onClick: () => {
				setCorrectionRecord(record)
				setTypeCorrection('contractor')
			}
		},
		{
			key: 'createAdjustment',
			label: 'Создать документ "Было/Стало"',
			icon: <PartitionOutlined />,
			disabled: !canCreateAdjustment,
			onClick: () => setAdjustmentRecord(record)
		}
	]

	const items: MenuProps['items'] = [
		{
			key: 'main',
			label: 'Основные',
			type: 'group',
			children: [
				{
					key: 'edit',
					label: 'Редактировать',
					icon: <EditOutlined />,
					disabled:
						record.adjustmentId !== null
							? record.status?.name === CONTAINER_STATUS.approved ||
							  (!user?.isAdmin &&
									!(
										checkPermissions([PERMISSIONS.AdjustmentCreate]) &&
										editableRow === undefined &&
										isAuthor
									))
							: !checkPermissions([PERMISSIONS.LocalEstimateCreate]) ||
							  (!checkPermissions([PERMISSIONS.LocalEstimateCreate]) && !isAuthor) ||
							  editableRow !== undefined,

					onClick: () => setEditableRow(record)
				},
				{
					key: 'delete',
					label:
						record.adjustmentId !== null
							? 'Удалить документ "Было/Стало"'
							: 'Удалить Корректировку Локальной сметы',
					danger: true,
					icon: <DeleteOutlined />,
					disabled: record.adjustmentId
						? !checkPermissions([PERMISSIONS.AdjustmentDelete])
						: record.ready || !user?.isAdmin,
					onClick: () => setContainerToRemove(record)
				}
			]
		},
		{
			key: 'correction',
			label: `Корректировка`,
			type: 'group',
			children: record.adjustmentId ? correctionAdjustment : correctionLocal
		},
		{
			key: 'importExport',
			label: `Импорт / Экспорт ${record.adjustmentId ? 'Было/Стало' : 'Локальной сметы'}`,
			type: 'group',
			children: WbsAndLocalActionsImportExport({ record, uploadRef, isLocal: true })
		},
		{
			key: 'relatedDocuments',
			label: 'Связанные документы',
			icon: <ForkOutlined />,
			onClick: () => {
				setSelectedId(record.id!)
				toggleShowModal(true)
			}
		}
	]

	useEffect(() => {
		setTimeout(() => {
			const fieldsErrors = localEstimateRegistryForm.getFieldsError()
			const isError = fieldsErrors.some(field => field.errors.length > 0)
			setHasErrors(isError)
		}, 10)
	}, [editable, allVal])

	return editable ? (
		<Row gutter={2} wrap={false}>
			<Col>
				<Tooltip title="Сохранить">
					<Button
						icon={<SaveOutlined />}
						type="primary"
						loading={isLoading}
						disabled={hasErrors}
						onClick={() => {
							useLocalEstimateRegistryState.setState({ recordSave: record })
							localEstimateRegistryForm.submit()
						}}
						style={{ marginRight: 8 }}
					/>
				</Tooltip>
			</Col>
			<Col>
				<Tooltip title="Отмена">
					<Button
						icon={<StopOutlined />}
						onClick={() => setEditableRow(undefined)}
						style={{ marginRight: 8 }}
					/>
				</Tooltip>
			</Col>
		</Row>
	) : (
		<>
			<Dropdown
				trigger={['click']}
				menu={{
					items: record.mainParent === null ? itemsEstimates : items,
					style: { minWidth: '256px' }
				}}
				placement="bottomRight"
			>
				<Button type="link" icon={<PlusSquareOutlined />} />
			</Dropdown>
			<span style={{ display: 'none' }}>
				<EstimateImportData
					containerId={record.adjustmentId ? record?.adjustmentId : record?.id}
					disabled={record?.ready}
					trigger={uploadRef}
					registry="localEstimateRegistry"
					isAdjustment={record.adjustmentId ? true : false}
				/>
			</span>
		</>
	)
}

// export const LocalEstimateRegistryActions: FC<IProps> = params => {
// const checkPermissions = useCheckPermissions()
// const { record, editable, isLoading, onSave } = params
// const {
// 	editableRow,
// 	setEditableRow,
// 	setCorrectionRecord,
// 	setAdjustmentRecord,
// 	setContainerToRemove
// } = useLocalEstimateRegistryState()

// const { user, permissions } = useAppSelector(state => state.environment)
// const wbsPermissions = useWbsPermits()

// const isAuthor =
// 	(user?.isAdmin ||
// 		record?.author?.id === user?.id ||
// 		record?.redactors.some(r => r.id === user?.id)) ??
// 	false

// const canCreateAdjustment: boolean =
// 	record.status?.name === 'Approved' &&
// 	(user?.isAdmin || checkPermissions([PERMISSIONS.AdjustmentCreate]))

// return editable ? (
// 	<Row gutter={2} wrap={false}>
// 		<Col>
// 			<Tooltip title="Сохранить">
// 				<Button
// 					icon={<SaveOutlined />}
// 					type="primary"
// 					loading={isLoading}
// 					onClick={() => onSave(record)}
// 					style={{ marginRight: 8 }}
// 				/>
// 			</Tooltip>
// 		</Col>
// 		<Col>
// 			<Tooltip title="Отмена">
// 				<Button
// 					icon={<StopOutlined />}
// 					onClick={() => setEditableRow(undefined)}
// 					style={{ marginRight: 8 }}
// 				/>
// 			</Tooltip>
// 		</Col>
// 	</Row>
// ) : (
// 	<Row gutter={6} wrap={false}>
// 		<Col>
// 			<Tooltip title="Создать Корректировку Локальной сметы">
// 				<Button
// 					data-attr="buttonCreateLocalEstimateCorrect"
// 					icon={<BranchesOutlined />}
// 					loading={isLoading}
// 					disabled={
// 						(record.status?.name !== 'Approved' && record.mainParent !== null) ||
// 						(!user?.isAdmin && !wbsPermissions.canLocalEdit) ||
// 						record.children?.length >= 1
// 					}
// 					onClick={() => setCorrectionRecord(record)}
// 				/>
// 			</Tooltip>
// 		</Col>
// 		<Col>
// 			<Tooltip title='Создать документ "Было/Стало"'>
// 				<Button
// 					data-attr="buttonCreateLocalEstimateAdjustment"
// 					icon={<PartitionOutlined />}
// 					loading={isLoading}
// 					disabled={!canCreateAdjustment}
// 					onClick={() => setAdjustmentRecord(record)}
// 				/>
// 			</Tooltip>
// 		</Col>
// 		{record.mainParent !== null && (
// 			<>
// 				<Col>
// 					<Tooltip title="Редактировать">
// 						<Button
// 							data-attr="buttonEditVOR"
// 							icon={<EditOutlined />}
// 							disabled={
// 								record.ready ||
// 								!wbsPermissions.canLocalEdit ||
// 								(wbsPermissions.canLocalEdit && !isAuthor) ||
// 								editableRow !== undefined
// 							}
// 							onClick={() => setEditableRow(record)}
// 						/>
// 					</Tooltip>
// 				</Col>
// 				<Col>
// 					<WbsToExcel row={record} registry="localEstimateRegistry" />
// 				</Col>
// 				<Col>
// 					<Tooltip title="Удалить Корректировку Локальной сметы">
// 						<Button
// 							data-attr="buttonDeleteVOR"
// 							type="primary"
// 							loading={isLoading}
// 							disabled={record.ready || !user?.isAdmin}
// 							danger
// 							onClick={() => setContainerToRemove(record)}
// 							icon={<DeleteOutlined />}
// 						/>
// 					</Tooltip>
// 				</Col>
// 			</>
// 		)}
// 	</Row>
// )
// }
