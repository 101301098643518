import { Card, Col, Row } from 'antd'
import { FC } from 'react'
import { Reports } from './reports'
import { ReportTable } from './reports-table'

export const ReportsMain: FC = () => {
	return (
		<Card>
			<Row justify="space-between">
				<Col>
					<Row gutter={8}>
						<Col>
							<Reports />
						</Col>
					</Row>
				</Col>
			</Row>
			<ReportTable />
		</Card>
	)
}
