import { Tabs } from 'antd'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setPageTitle } from 'store/rootSlice'
import { WbsStatusReport } from 'widgets/reports/WbsStatusReport/ui/WbsStatusReport'
import { HOCReport } from './HOCReport'
import LightReport from './lightReport/LightReport'
import { MobileReportRegistry } from './mobileReport/ui/MobileReportRegistry'
import Report from './Report'
import { ReportsMain } from './Reports1C/ui/reports-main'
import Reports1C from './Reports1C'

const AnalyticsPage: FC = () => {
	const dispatch = useAppDispatch()
	const checkPermissions = useCheckPermissions()
	const { permissions } = useAppSelector(state => state.environment)

	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Отчёты', subTitle: 'Отчет' }))
	}, [])

	const onTabChange = (key: string) => {
		dispatch(setPageTitle({ subTitle: tabItems.find(tab => tab.key === key)?.label }))
	}

	const tabItems = [
		{
			label: 'Главная',
			key: 'main',
			children: <Report />
		},
		{
			label: 'Светофор',
			key: 'lightReport',
			children: <LightReport />,
			disabled: !checkPermissions([PERMISSIONS.ChecklistsReportLight])
		},
		{
			label: 'Отчёты',
			key: 'reports',
			children: <Reports1C />,
			disabled: !checkPermissions([PERMISSIONS.ChecklistsReportLight])
		},
		{
			label: 'Отчёт РСО',
			key: 'HOCreport',
			children: <HOCReport />,
			disabled: !checkPermissions([PERMISSIONS.ReportHOC])
		},
		// {
		// 	label: 'Отчёт РСО/Штрафы',
		// 	key: 'HOCFineReport',
		// 	children: <HOCFineReport />,
		// 	disabled: !checkPermissions(['ReportRCOFine'])
		// }
		{
			label: 'Отчёт по статусам ВОР',
			key: 'WbsStatusReport',
			children: <WbsStatusReport />,
			disabled: !checkPermissions([PERMISSIONS.ReportContainerStatus, PERMISSIONS.Admin])
		},
		{
			label: 'Отчёт по работе с мобильным приложением',
			key: 'MobileReport',
			children: <MobileReportRegistry />,
			disabled: !checkPermissions([PERMISSIONS.ReportMobile])
		},
		{
			label: 'Реестр замечаний',
			key: 'authorReports',
			children: <ReportsMain />,
			disabled: !checkPermissions([PERMISSIONS.IssueReport, PERMISSIONS.ContractorReport])
		}
	]

	return (
		<Tabs
			destroyInactiveTabPane={true}
			items={tabItems}
			defaultActiveKey="main"
			onTabClick={key => onTabChange(key)}
		/>
	)
}
export default AnalyticsPage
