import { IBaseConstructionObject } from 'interfaces/IBase'
import { TBase } from 'shared/schemas'

export const markDisabledNodes = (items: IBaseConstructionObject[] | TBase[]): IBaseConstructionObject[] | TBase[]=> {
	return items?.filter((item: any) => !item.isDeleted).map((item: any) => {
		const disabled =
			item.isActual === false ||
			item.isDeleted === true ||
			item.isGroup === true ||
			item.isSelectAvailable === false
		return {
			...item,
			disabled,
			children: markDisabledNodes(item.children)
		}
	})
}
