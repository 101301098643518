import { Col, Row } from 'antd'
import { FC } from 'react'
import { Pie } from 'react-chartjs-2'
import { useCountOrdinancesDashboard } from '../model/diagrams-query'

export const OrdinanceDashboard: FC = () => {
	const { data: ordinance } = useCountOrdinancesDashboard()
	const allValues = [
		ordinance?.countOrdinanceEliminated || null,
		ordinance?.countOrdinanceNonEliminated || null,
		ordinance?.countOrdinanceExpired || null
	]

	const values = [
		ordinance?.countOrdinanceEliminated || null,
		ordinance?.countOrdinanceNonEliminated || null
	]
	const labels = [`Устранено`, 'Не устранено', 'Просрочено']
	const customLabels = labels.map((label, index) => `${label}: ${allValues[index] ?? 0}`)
	const config = {
		type: 'doughnut',
		data: {
			labels: customLabels,
			datasets: [
				{
					data: [...values, null],
					backgroundColor: ['#0770C5', '#8099A8', '#7A77D9'],
					borderColor: ['white'],
					borderWidth: 4,
					datalabels: {
						color: '#fff',
						font: {
							size: 24
						}
					}
				},
				{
					data: [null, null, ordinance?.countOrdinanceExpired],
					backgroundColor: ['#0770C5', '#8099A8', '#7A77D9'],
					borderColor: ['white'],
					borderWidth: 4,
					datalabels: {
						color: '#fff',
						font: {
							size: 24
						}
					}
				}
			]
		},
		options: {
			maintainAspectRatio: false,
			plugins: {
				legend: {
					responsive: true,
					position: 'bottom' as const,
					title: {
						display: true,
						color: 'black'
					}
				}
			}
		}
	}

	return (
		<Row
			wrap={false}
			gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: '#f2f2f2',
				height: 650
			}}
		>
			<Col span={24} style={{ padding: '1rem', background: '#fefefe' }}>
				<h2>ПРЕДПИСАНИЯ</h2>
				<div style={{ height: 550 }}>
					<Pie {...config} />
				</div>
			</Col>
		</Row>
	)
}
