import { useMutation, useQuery } from '@tanstack/react-query'
import { TWbsTaskForm } from 'entities/wbs-task/model/wbs-task-schema'
import { useAppSelector } from 'hooks/appReduxHook'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import { WBS_TASK_STATUS } from 'shared/constants/wbs-task-types'
import { useWbsTaskCardState } from '../model/wbs-task-card-state'
import { wbsTaskCardApi } from './wbs-task-card-api'
import { Modal } from 'antd'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'

export const useWbsTaskCard = () => {
	const { currentTaskId } = useWbsTaskState()
	const { setShowCard } = useWbsTaskCardState()
	const closeHandler = () => {
		setShowCard(false)
	}
	return useQuery({
		queryKey: ['wbsTaskCard', currentTaskId],
		queryFn: async () => {
			if (currentTaskId) {
				const wbsTaskCard = await wbsTaskCardApi.get(currentTaskId!).then(data => data)
				return wbsTaskCard
			}
		},
		onSuccess: () => setShowCard(true),
		onError: (err: AxiosError<IErrorDetail>) => {
			if (err.response?.status === 403) {
				Modal.error({
					title: 'Ошибка доступа',
					content:
						err.response?.data?.detail,
					onOk: closeHandler,
					onCancel: closeHandler
				})
			}
		},
		select: data => data,
		retry: (_, error) => (error.response?.status === 403 ? false : true),
		refetchOnWindowFocus: false
	})
}

export const useWbsTaskAttachments = (id: string) => {
	return useQuery({
		queryKey: ['wbsTaskCard', 'attachments', id],
		queryFn: async () => {
			return await wbsTaskCardApi.getAttachments(id).then(data => data)
		},
		select: data => data,
		refetchOnWindowFocus: false
	})
}

export const useWbsTaskCardType = () => {
	return useQuery({
		queryKey: ['wbsTaskCard', 'type'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await wbsTaskCardApi.getWbsTaskType()
		}
	})
}

export const useWbsTaskCardConstructionObjects = () => {
	return useQuery({
		queryKey: ['wbsTaskCard', 'constructionObjects'],
		queryFn: async () => {
			return await wbsTaskCardApi.getConstructionObjects().then(data => data)
		},
		select: data => data,
		refetchOnWindowFocus: false
	})
}

export const useWbsTaskCardWorkPackages = () => {
	return useQuery({
		refetchOnWindowFocus: false,
		queryKey: ['wbsTaskCard', 'workPackages'],
		queryFn: async () => {
			return await wbsTaskCardApi.getWorkPackages()
		},
		select: data => data.map(d => ({ ...d, name: `${d.code} ${d.name}` }))
	})
}

export const useWbsTaskCardTypeSections = () => {
	return useQuery({
		refetchOnWindowFocus: false,
		queryKey: ['wbsTaskCard', 'typeSections'],
		queryFn: async () => {
			const typeSections = await wbsTaskCardApi.getTypeSections()
			return typeSections?.filter(ts => !ts.isDeleted)
		}
	})
}

export const useWbsTaskCardContracts = (projectId?: string) => {
	const project = useAppSelector(state => state.environment.project)
	return useQuery({
		queryKey: ['wbsTaskCard', 'contracts'],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			return await wbsTaskCardApi.getContracts(projectId ?? project.id)
		}
	})
}

export const useWbsTaskCardPotentialAssignUsers = (status: string) => {
	return useQuery({
		queryKey: ['wbsTaskCard', 'potentialAssignUsers', status],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			const potentialAssignUsers = await wbsTaskCardApi.getPotentialAssignUsers(status ?? WBS_TASK_STATUS.new)
			return potentialAssignUsers?.filter(pau => !pau.isDeleted)
		}
	})
}

export const useWbsTaskCardEstimates = (contractId: string, constructionObjectId: string) => {
	const { currentPage, setTotalItems } = useWbsTaskCardState()
	return useQuery({
		queryKey: ['wbsTaskCard', 'estimates', currentPage, contractId, constructionObjectId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			const estimate = await wbsTaskCardApi.getWbsTaskEstimate(contractId, constructionObjectId, undefined, currentPage)
			setTotalItems(estimate.totalItems)
			return estimate.data
		}
	})
}

export const useWbsTaskCardContainers = (typeSectionId: string, constructionObjectId: string) => {
	const { currentPage, setTotalItems } = useWbsTaskCardState()
	return useQuery({
		queryKey: ['wbsTaskCard', 'containers', currentPage, typeSectionId, constructionObjectId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			const container = await wbsTaskCardApi.getWbsTaskContainer(typeSectionId, constructionObjectId, undefined, currentPage)
			setTotalItems(container.totalItems)
			return container.data
		}
	})
}

export const useWbsTaskUpdate = () => {
	return useMutation({
		mutationKey: ['wbsTask', 'update'],
		mutationFn: async ({ id, dto }: { id: string; dto: TWbsTaskForm }) =>
			await wbsTaskCardApi.update(id, dto)
	})
}

export const useWbsTaskSave = () => {
	return useMutation({
		mutationKey: ['wbsTask', 'save'],
		mutationFn: async (dto: TWbsTaskForm) => await wbsTaskCardApi.save(dto)
	})
}