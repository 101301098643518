import { Button, Checkbox, Col, DatePicker, Form, Row, Select, Tag } from 'antd'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { Tooltip } from 'antd/lib'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import {
	useContractors,
	useCountContractors,
	useProjects1C,
	useProjectsData
} from '../model/diagrams-query'
import { useDiagramState } from '../model/diagrams-state'
import { IContractor, IProject, IProject1C } from '../model/diagrams-types'
import { useAppSelector } from 'hooks/appReduxHook'

interface DiagramsFilterProps {}
interface IDiagramFilters {
	range: [Dayjs, Dayjs]
	project1CIds: string[]
	projectIds: string[]
	contractorIds: string[]
	allPeriod?: boolean
}

export const DiagramsFilter: React.FC<DiagramsFilterProps> = ({}) => {
	const [filtersForm] = Form.useForm<IDiagramFilters>()
	const formValues = Form.useWatch(values => values, filtersForm)
	const notCanUse =
		formValues?.contractorIds === undefined ||
		formValues?.project1CIds === undefined ||
		formValues?.projectIds === undefined ||
		formValues?.contractorIds?.length === 0 ||
		formValues?.project1CIds?.length === 0 ||
		formValues?.projectIds?.length === 0 ||
		((formValues?.range === null || formValues?.range === undefined) &&
			(formValues?.allPeriod === false || formValues?.allPeriod === undefined))
	const { setFilters, to, from, project1CIds, projectIds, contractorIds } = useDiagramState()
	const [projectIdsBlur, setProjectIdsBlur] = useState<string[] | null>(null)
	const [project1CIdsBlur, setProject1CIdsBlur] = useState<string[] | null>(null)
	const [contractorIdsBlur, setContractorIdsBlur] = useState<string[] | null>(null)
	const { data: projects1CData, isLoading: isProjects1CLoading } = useProjects1C(
		projectIdsBlur! ?? filtersForm.getFieldValue('projectIds'),
		contractorIdsBlur! ?? filtersForm.getFieldValue('contractorIds')
	)
	const { data: projectsData, isLoading: isProjectsLoading } = useProjectsData(
		project1CIdsBlur! ?? filtersForm.getFieldValue('project1CIds'),
		contractorIdsBlur! ?? filtersForm.getFieldValue('contractorIds')
	)
	const { data: contractorsData, isLoading: isContractorsLoading } = useContractors(
		projectIdsBlur! ?? filtersForm.getFieldValue('projectIds'),
		project1CIdsBlur! ?? filtersForm.getFieldValue('project1CIds')
	)

	const { data: countContractorsData, isLoading: isLoadingContractors } = useCountContractors()

	const [project1C, setProject1C] = useState<string | null>(null)
	const [selectedProjects, setSelectedProjects] = useState<string[]>([])
	const [contractor, setContractor] = useState<string | null>(null)

	const filteredProjects: IProject[] = useMemo(() => {
		let result = projectsData || []

		return result
	}, [projectsData, project1C, contractor])

	const filteredContractors: IContractor[] = useMemo(() => {
		let result: IProject1C[] = contractorsData || []

		return result
	}, [contractorsData, project1C, selectedProjects])

	const onConfirmFilters = async (filters: IDiagramFilters) => {
		if (filters) {
			setFilters({
				to: formValues?.allPeriod === true ? null : dayjs(filters.range?.[1]).format('YYYY-MM-DD'),
				from:
					formValues?.allPeriod === true ? null : dayjs(filters.range?.[0]).format('YYYY-MM-DD'),
				project1CIds: filtersForm.getFieldValue('project1CIds') ?? filters?.project1CIds,
				projectIds: filtersForm.getFieldValue('projectIds') ?? filters?.projectIds,
				contractorIds: filtersForm.getFieldValue('contractorIds') ?? filters?.contractorIds
			})
		}
	}

	useEffect(() => {
		if (
			filtersForm.getFieldValue('projectIds')?.length! !== projectsData?.length! &&
			projectIdsBlur !== null
		) {
			filtersForm.setFieldValue(
				'projectIds',
				projectsData?.filter(elem => projectIdsBlur?.includes(elem.id))?.map(p => p.id)
			)
		}
	}, [projectsData])

	useEffect(() => {
		if (
			filtersForm.getFieldValue('contractorIds')?.length! !== contractorsData?.length! &&
			contractorIdsBlur !== null
		) {
			filtersForm.setFieldValue(
				'contractorIds',
				contractorsData?.filter(elem => contractorIdsBlur?.includes(elem.id))?.map(c => c.id)
			)
		}
	}, [contractorsData])

	useEffect(() => {
		if (
			filtersForm.getFieldValue('project1CIds')?.length! !== projects1CData?.length! &&
			project1CIdsBlur !== null
		) {
			filtersForm.setFieldValue(
				'project1CIds',
				projects1CData?.filter(elem => project1CIdsBlur?.includes(elem.id))?.map(p => p.id)
			)
		}
	}, [projects1CData])

	const onBlur = (type: 'project1CIds' | 'projectIds' | 'contractorIds') => {
		if (type === 'project1CIds') {
			setProject1CIdsBlur(filtersForm.getFieldValue('project1CIds'))
		}
		if (type === 'projectIds') {
			setProjectIdsBlur(filtersForm.getFieldValue('projectIds'))
		}
		if (type === 'contractorIds') {
			setContractorIdsBlur(filtersForm.getFieldValue('contractorIds'))
		}
	}

	useEffect(() => {
		if (projectIdsBlur === null && contractorIdsBlur === null && project1CIdsBlur === null) {
			filtersForm.setFieldsValue({
				range:
					to && from
						? [dayjs(from, 'YYYY-MM-DD'), dayjs(to, 'YYYY-MM-DD')]
						: [dayjs('2024-12-01', 'YYYY-MM-DD'), dayjs('2024-12-31', 'YYYY-MM-DD')],
				project1CIds: project1CIds ?? projects1CData?.map(pc => pc.id),
				projectIds: projectIds ?? projectsData?.map(p => p.id),
				contractorIds: contractorIds ?? contractorsData?.map(c => c.id)
			})
			setFilters({
				project1CIds: filtersForm.getFieldValue('project1CIds'),
				projectIds: filtersForm.getFieldValue('projectIds'),
				contractorIds: filtersForm.getFieldValue('contractorIds'),
				to:
					formValues?.allPeriod === true
						? null
						: to ?? dayjs('2024-12-31T02:00:00+03:00').format('YYYY-MM-DD'),
				from:
					formValues?.allPeriod === true
						? null
						: from ?? dayjs('2024-12-01T02:00:00+03:00').format('YYYY-MM-DD')
			})
			if (project1CIds?.length! >= 0 && projectIds?.length! >= 0 && contractorIds?.length! >= 0) {
				setProject1CIdsBlur(project1CIds! ?? projects1CData?.map(pc => pc.id))
				setProjectIdsBlur(projectIds! ?? projectsData?.map(p => p.id))
				setContractorIdsBlur(contractorIds! ?? contractorsData?.map(c => c.id))
			}
		}
	}, [isProjects1CLoading, isProjectsLoading, isContractorsLoading])

	return (
		<Form
			form={filtersForm}
			name="filtersForm"
			onFinish={() => onConfirmFilters(filtersForm.getFieldsValue())}
		>
			{/* <Space
				direction="horizontal"
				align="baseline"
				style={{
					display: 'flex',
					justifyContent: 'flex-start',
					flexWrap: 'wrap',
					gap: '16px',
					padding: '16px',
					backgroundColor: '#f0f2f5',
					borderRadius: '10px',
					marginBottom: '16px'
				}}
			> */}
			<Row justify="start" gutter={8} align="bottom">
				<Col>
					<Col>
						<Row style={{ height: '33px' }} justify="start">
							<Form.Item name="allPeriod">
								{/* <span>Период</span> */}
								<span style={{ marginRight: '8px', marginLeft: '5px' }}>За всё время</span>
								<Checkbox
									checked={formValues?.allPeriod}
									onChange={value => filtersForm.setFieldValue('allPeriod', value.target.checked)}
								/>
							</Form.Item>
						</Row>
					</Col>
					<Col>
						<Form.Item name={'range'}>
							<DatePicker.RangePicker
								locale={locale}
								format="DD.MM.YYYY"
								disabled={formValues?.allPeriod === true}
								style={{
									width: '100%',
									maxWidth: '250px',
									borderRadius: '8px',
									boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
									height: '40px'
								}}
							/>
						</Form.Item>
					</Col>
				</Col>
				<Col>
					<Col>
						<Row style={{ marginLeft: '5px', marginBottom: '4px' }}>Проекты 1C</Row>
					</Col>
					<Col>
						<Form.Item name={'project1CIds'}>
							<Select
								loading={isProjects1CLoading}
								placeholder="Проекты 1С"
								allowClear
								mode="multiple"
								value={project1C}
								onChange={value => setProject1C(value)}
								style={{
									minWidth: '200px',
									maxWidth: '250px',
									borderRadius: '8px',
									boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
									height: '40px'
								}}
								tagRender={props => (
									<Tag
										style={{
											width: '100%',
											maxWidth: '140px',
											fontSize: 14,
											marginTop: '2px',
											marginRight: '4px',
											padding: '4px 8px',
											overflow: 'hidden',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis'
										}}
									>
										{props.label}
									</Tag>
								)}
								onBlur={() => onBlur('project1CIds')}
								dropdownRender={menu => (
									<>
										{menu}
										<Button
											type="link"
											onClick={() => {
												filtersForm.getFieldValue('project1CIds')?.length! ===
												projects1CData?.length!
													? filtersForm.setFieldValue('project1CIds', [])
													: filtersForm.setFieldValue(
															'project1CIds',
															projects1CData?.map(pc => pc.id)
													  )
											}}
										>
											{filtersForm.getFieldValue('project1CIds')?.length! ===
											projects1CData?.length!
												? 'Снять всё'
												: 'Выбрать всё'}
										</Button>
									</>
								)}
								options={projects1CData?.map(p => ({
									label: p.name,
									value: p.id
								}))}
								maxTagCount={1}
							/>
						</Form.Item>
					</Col>
				</Col>
				<Col>
					<Col>
						<Row style={{ marginLeft: '5px', marginBottom: '4px' }}>Объекты строительства</Row>
					</Col>
					<Col>
						<Form.Item name={'projectIds'}>
							<Select
								loading={isProjectsLoading}
								mode="multiple"
								placeholder="Объекты строительства"
								allowClear
								value={selectedProjects}
								onChange={values => setSelectedProjects(values)}
								tagRender={props => (
									<Tag
										style={{
											width: '100%',
											maxWidth: '140px',
											fontSize: 14,
											marginTop: '2px',
											marginRight: '4px',
											padding: '4px 8px',
											overflow: 'hidden',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis'
										}}
									>
										{props.label}
									</Tag>
								)}
								style={{
									minWidth: '200px',
									maxWidth: '250px',
									boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
									height: '40px'
								}}
								options={filteredProjects?.map(p => ({
									label: p.name,
									value: p.id
								}))}
								onBlur={() => onBlur('projectIds')}
								dropdownRender={menu => (
									<>
										{menu}
										<Button
											type="link"
											onClick={() =>
												filtersForm.getFieldValue('projectIds')?.length! === projectsData?.length!
													? filtersForm.setFieldValue('projectIds', [])
													: filtersForm.setFieldValue(
															'projectIds',
															projectsData?.map(pc => pc.id)
													  )
											}
										>
											{filtersForm.getFieldValue('projectIds')?.length! === projectsData?.length!
												? 'Снять всё'
												: 'Выбрать всё'}
										</Button>
									</>
								)}
								maxTagCount={1}
							/>
						</Form.Item>
					</Col>
				</Col>
				<Col>
					<Col>
						<Row style={{ marginLeft: '5px', marginBottom: '4px' }}>Подрядчики</Row>
					</Col>
					<Col>
						<Form.Item name={'contractorIds'}>
							<Select
								loading={isContractorsLoading}
								placeholder="Подрядчики"
								allowClear
								mode="multiple"
								value={contractor}
								onChange={value => setContractor(value)}
								tagRender={props => (
									<Tag
										style={{
											width: '100%',
											maxWidth: '140px',
											fontSize: 14,
											padding: '4px 8px',
											marginTop: '2px',
											marginRight: '4px',
											overflow: 'hidden',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis'
										}}
									>
										{props.label}
									</Tag>
								)}
								style={{
									minWidth: '200px',
									maxWidth: '250px',
									borderRadius: '8px',
									boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
									height: '40px'
								}}
								onBlur={() => onBlur('contractorIds')}
								dropdownRender={menu => (
									<>
										{menu}
										<Button
											type="link"
											onClick={() =>
												filtersForm.getFieldValue('contractorIds')?.length! ===
												contractorsData?.length!
													? filtersForm.setFieldValue('contractorIds', [])
													: filtersForm.setFieldValue(
															'contractorIds',
															contractorsData?.map(c => c.id)
													  )
											}
										>
											{filtersForm.getFieldValue('contractorIds')?.length! ===
											contractorsData?.length!
												? 'Снять всё'
												: 'Выбрать всё'}
										</Button>
									</>
								)}
								options={filteredContractors?.map(c => ({
									label: c.name,
									value: c.id
								}))}
								maxTagCount={1}
							/>
						</Form.Item>
					</Col>
				</Col>
				<Col>
					<Tooltip title={notCanUse ? 'Для применения заполните все фильтры' : ''}>
						<Button
							type="primary"
							onClick={() => filtersForm.submit()}
							disabled={notCanUse}
							style={{ marginBottom: '30px' }}
						>
							Применить
						</Button>
					</Tooltip>
				</Col>
				<Col>
					<Button
						type="primary"
						onClick={() => {
							filtersForm.resetFields()
							setProjectIdsBlur([])
							setProject1CIdsBlur([])
							setContractorIdsBlur([])
						}}
						style={{ marginBottom: '30px' }}
					>
						Сбросить
					</Button>
				</Col>
			</Row>
			{/* </Space> */}
		</Form>
	)
}
