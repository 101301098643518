import { FilterOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Form, Input, Row, Tooltip } from 'antd'
import { Switch } from 'antd/lib'
import { useAppDispatch } from 'hooks/appReduxHook'
import { useEffect } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setPageTitle } from 'store/rootSlice'
import { DashboardLayout } from 'widgets/dashboards'
import { WbsTaskCard } from 'widgets/wbs-task-card'
import { WbsTaskRegistry } from 'widgets/wbs-task-registry'
import { useWbsTaskState } from './model/wbs-tasks-state'
import WbsTasksFilters from './ui/wbs-tasks-filters'
import { useWbsTaskCardState } from 'widgets/wbs-task-card/model/wbs-task-card-state'

export const WbsTasksPage = () => {
	const dispatch = useAppDispatch()
	const checkPermissions = useCheckPermissions()
	const canCreate = checkPermissions([PERMISSIONS.WbsTasksCreate])
	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Заявки ВОР' }))
	}, [])

	const { showFilters, wbsTaskFilters, openCard, setShowFilters, setWbsTaskFilters, onPagination } =
		useWbsTaskState()
	const { setShowCard } = useWbsTaskCardState()
	const queryClient = useQueryClient()

	const onSearch = (searchValue: string | undefined) => {
		const filters = { ...wbsTaskFilters, search: searchValue! }
		setWbsTaskFilters({ ...filters })
		onPagination(1)
	}

	return (
		<>
			<WbsTasksFilters open={showFilters} />
			<DashboardLayout
				body={<WbsTaskRegistry />}
				overlays={[<WbsTaskCard />]}
				headerLeft={[
					// <Button icon={<SettingOutlined />} />,
					<Tooltip title='Обновить'>
						<Button
							icon={<ReloadOutlined />}
							onClick={() => queryClient.invalidateQueries({ queryKey: ['wbsTasks'] })}
						/>
					</Tooltip>,
					<Tooltip title='Фильтры'>
						<Button
							icon={<FilterOutlined />}
							onClick={() => setShowFilters(true)}
							type={
								Object.entries(wbsTaskFilters).some(
									x =>
										x[0] !== 'search' &&
										x[0] !== 'myWbsTask' &&
										x[0] !== 'meAssignedUsers' &&
										typeof x[1] !== 'undefined'
								)
									? 'primary'
									: 'default'
							}
						/>
					</Tooltip>,
					<Input.Search
						allowClear
						addonAfter={false}
						placeholder="Поиск..."
						defaultValue={wbsTaskFilters.search}
						onSearch={value => onSearch(value)}
						style={{ width: 256 }}
					/>,
					<Form>
						<Row>
							<Form.Item label="Я автор" style={{ margin: '0 0 0 20px' }}>
								<Switch
									size="small"
									onChange={() => {
										setWbsTaskFilters({ ...wbsTaskFilters, myWbsTask: !wbsTaskFilters.myWbsTask })
										onPagination(1)
									}}
								/>
							</Form.Item>
							<Form.Item label="Я исполнитель" style={{ margin: '0 0 0 20px' }}>
								<Switch
									size="small"
									onChange={() => {
										setWbsTaskFilters({
											...wbsTaskFilters,
											meAssignedUsers: !wbsTaskFilters.meAssignedUsers
										})
										onPagination(1)
									}}
								/>
							</Form.Item>
						</Row>
					</Form>
				]}
				headerRight={[
					<Button
						type="primary"
						icon={<PlusOutlined />}
						onClick={() => {
							openCard('wbsTask')
							setShowCard(true)
						}}
						disabled={!canCreate}
					>
						Создать заявку
					</Button>
				]}
			/>
		</>
	)
}
