import { LoadingOutlined } from '@ant-design/icons'
import { Card, Col, Row, Spin } from 'antd'
import React, { useState } from 'react'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import {
	useContainerDashboard,
	useCountChecklistsDashboard,
	useCountContractors,
	useCountIssues,
	useCountOrdinanceFinesDashboard,
	useCountOrdinancesDashboard
} from '../model/diagrams-query'

export const SummaryDashboard: React.FC = () => {
	const [data, setData] = useState({
		totalOrganizations: 0,
		totalWOR: 0,
		totalIssues: 0,
		totalOrdinances: 0,
		totalOrdinanceFines: 0,
		totalChecklists: 0
	})
	const { data: contractorsData, isLoading: isLoadingContractors } = useCountContractors()
	const { data: ordinanceFinesValues, isLoading: isLoadingOrdinanceFines } =
		useCountOrdinanceFinesDashboard()
	const { data: containerValues, isLoading: isLoadingContainer } = useContainerDashboard()
	const { data: issue, isLoading: isLoadingIssue } = useCountIssues()
	const { data: ordinance, isLoading: isLoadingOrdinance } = useCountOrdinancesDashboard()
	const { data: checklistData, isLoading: isLoadingChecklist } = useCountChecklistsDashboard()
	const checkPermissions = useCheckPermissions()
	const showWidgets = {
		issue: checkPermissions([PERMISSIONS.IssueView]),
		ordinance: checkPermissions([PERMISSIONS.OrdinanceView]),
		checklist: checkPermissions([PERMISSIONS.ChecklistsUkKc, PERMISSIONS.ChecklistsUksNdc]),
		ordinanceFine: checkPermissions([PERMISSIONS.OrdinanceFine]),
		wbs: checkPermissions([PERMISSIONS.WbsView]),
		allCompanies: checkPermissions([PERMISSIONS.AllCompanies])
	}
	const loader = <LoadingOutlined style={{ fontSize: '64px' }} spin />

	const containerStyle = {
		padding: '16px',
		backgroundColor: '#f9f9f9'
	}

	const cardStyle = {
		background: '#ffffff',
		textAlign: 'center' as const,
		borderRadius: '8px',
		boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
		padding: '16px',
		height: '100px',
		display: 'flex',
		flexDirection: 'column' as const,
		justifyContent: 'center'
	}

	const numberStyle = {
		fontSize: '48px',
		fontWeight: 'bold' as const,
		color: '#1890ff',
		margin: 0
	}

	const labelStyle = {
		fontSize: '16px',
		color: '#595959'
	}

	return (
		<Row gutter={[16, 16]}>
			{showWidgets.allCompanies && (
				<Col xs={12} sm={6} md={4}>
					<Card style={cardStyle}>
						{isLoadingContractors ? (
							<Spin indicator={loader} />
						) : (
							<>
								<p style={numberStyle}>{contractorsData?.count!}</p>
								<p style={labelStyle}>ОРГАНИЗАЦИИ</p>
							</>
						)}
					</Card>
				</Col>
			)}
			{showWidgets.wbs && (
				<Col xs={12} sm={6} md={4}>
					<Card style={cardStyle}>
						{isLoadingContainer ? (
							<Spin indicator={loader} />
						) : (
							<>
								<p style={numberStyle}>{containerValues?.totalContainers}</p>
								<p style={labelStyle}>ВОР</p>
							</>
						)}
					</Card>
				</Col>
			)}
			{showWidgets.issue && (
				<Col xs={12} sm={6} md={4}>
					<Card style={cardStyle}>
						{isLoadingIssue ? (
							<Spin indicator={loader} />
						) : (
							<>
								<p style={numberStyle}>{issue?.totalIssues!}</p>
								<p style={labelStyle}>ЗАМЕЧАНИЯ</p>
							</>
						)}
					</Card>
				</Col>
			)}
			{showWidgets.ordinance && (
				<Col xs={12} sm={6} md={4}>
					<Card style={cardStyle}>
						{isLoadingOrdinance ? (
							<Spin indicator={loader} />
						) : (
							<>
								<p style={numberStyle}>{ordinance?.totalOrdinances}</p>
								<p style={labelStyle}>ПРЕДПИСАНИЯ</p>
							</>
						)}
					</Card>
				</Col>
			)}
			{showWidgets.ordinanceFine && (
				<Col xs={12} sm={6} md={4}>
					<Card style={cardStyle}>
						{isLoadingOrdinanceFines ? (
							<Spin indicator={loader} />
						) : (
							<>
								<p style={numberStyle}>{ordinanceFinesValues?.totalOrdinanceFines}</p>
								<p style={labelStyle}>ШТРАФЫ</p>
							</>
						)}
					</Card>
				</Col>
			)}
			{showWidgets.checklist && (
				<Col xs={12} sm={6} md={4}>
					<Card style={cardStyle}>
						{isLoadingChecklist ? (
							<Spin indicator={loader} />
						) : (
							<>
								<p style={numberStyle}>{checklistData?.totalCheckLists}</p>
								<p style={labelStyle}>ЧЕК-ЛИСТЫ</p>
							</>
						)}
					</Card>
				</Col>
			)}
		</Row>
	)
}
