import { Col, Row } from 'antd'
import { ArcElement, Chart as ChartJS, Legend, Title, Tooltip } from 'chart.js'
import { FC } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useCountOrdinanceFinesDashboard } from '../model/diagrams-query'

ChartJS.register(ArcElement, Tooltip, Legend, Title)

export interface IDoughnutValues {
	eliminated: number
	fixedOut: number
	notEliminated: number
	total: number
}

export const OrdinanceFinesDashboard: FC = () => {
	const { data: ordinanceFinesValues } = useCountOrdinanceFinesDashboard()

	const values = [
		ordinanceFinesValues?.countOrdinanceFinesNew || null,
		ordinanceFinesValues?.countOrdinanceFinesCanceled || null,
		ordinanceFinesValues?.countOrdinanceFinesCollected || null,
		ordinanceFinesValues?.countOrdinanceFinesExhibited || null
	]
	const labels = [`Новый`, 'Снят', 'Удержан', 'Выставлен']
	const customLabels = labels.map((label, index) => `${label}: ${values[index] ?? 0}`)
	const data = {
		data: {
			labels: customLabels,
			datasets: [
				{
					data: values,
					backgroundColor: ['#FFAD14', '#0770C5', '#7A77D9', '#8099A8'],
					borderColor: ['white'],
					borderWidth: 1,
					datalabels: {
						color: '#fff',
						font: {
							size: 24
						}
					}
				}
			]
		},
		options: {
			maintainAspectRatio: false,
			plugins: {
				legend: {
					responsive: true,
					position: 'bottom' as const,
					title: {
						display: true,
						color: 'black'
					}
				}
			}
		}
	}

	return (
		<Row
			wrap={false}
			gutter={12}
			style={{
				border: '1px solid #ddd',
				borderRadius: 8,
				padding: 8,
				background: '#f2f2f2',
				height: 650
			}}
		>
			<Col span={24} style={{ padding: '1rem', background: '#fefefe' }}>
				<h2>ШТРАФЫ</h2>
				<div style={{ height: 550 }}>
					<Doughnut {...data} />
				</div>
			</Col>
		</Row>
	)
}
