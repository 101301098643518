import { useQuery } from "@tanstack/react-query";
import { diagramApi } from "../api/diagrams-api";
import { useDiagramState } from "./diagrams-state";



export const useProjects1C = (projectIds?: string[], contractorIds?: string[]) => {
  return useQuery({
    queryKey: ['project1C', 'diagrams', projectIds, contractorIds],
    queryFn: async () => {
      return await diagramApi.getProjects1C(projectIds, contractorIds).then(data => data)
    }
  })
}

export const useProjectsData = (project1CIds?: string[], contractorIds?: string[]) => {
  return useQuery({
    queryKey: ['projects', 'diagrams', project1CIds, contractorIds],
    queryFn: async () => {
      return await diagramApi.getProjects(project1CIds, contractorIds).then(data => data)
    }
  })
}

export const useContractors = (projectIds?: string[], project1CIds?: string[]) => {
  return useQuery({
    queryKey: ['contractors', projectIds, project1CIds],
    queryFn: async () => {
      return await diagramApi.getContractors(projectIds, project1CIds).then(data => data)
    }
  })
}


export const useCountContractors = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['countContractors', to, from, project1CIds, projectIds, contractorIds],
    queryFn: async () => {
      if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
      return await diagramApi.getCountContractors({to, from, project1CIds, projectIds, contractorIds}).then(data => data)
      }
      return null
    },
    select: data => data,
    refetchOnWindowFocus: false
  })
}

export const useCountIssues = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['countIssues', to, from, project1CIds, projectIds, contractorIds],
    queryFn: async () => {
      if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
        return await diagramApi.getCountIssues({to, from, project1CIds, projectIds, contractorIds}).then(data => data)
      }
      return null
    },
    select: data => data,
    refetchOnWindowFocus: false
  })
}

export const useRatingDashboard = () => {
  const {to, from} = useDiagramState()
  return useQuery({
    queryKey: ['ratingDashboard', to, from],
    queryFn: async () => {
      if (to !== undefined && from !== undefined){
      return await diagramApi.getContractorsRating({to, from}).then(data => data)
      }
      return null
    },
    select: data => data,
    refetchOnWindowFocus: false
  })
}

export const useIssueSubTypesDashboard = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['issueSubTypesDashboard', to, from, project1CIds, projectIds, contractorIds],
    queryFn: async () => {
      if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
      return await diagramApi.getIssueSubTypes({to, from, project1CIds, projectIds, contractorIds}).then(data => data)
      }
      return null
    },
    select: data => data,
    refetchOnWindowFocus: false
  })
}

export const useContainerDashboard = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['containerDashboard', to, from, project1CIds, projectIds, contractorIds],
    queryFn: async () => {
      if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
      return await diagramApi.getCountContainers({to, from, project1CIds, projectIds, contractorIds}).then(data => data)
      }
      return null
    },
    select: data => data,
    refetchOnWindowFocus: false
  })
}

export const useCountOrdinancesDashboard = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['countOrdinancesDashboard', to, from, project1CIds, projectIds, contractorIds],
    queryFn: async () => {
      if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
      return await diagramApi.getCountOrdinances({to, from, project1CIds, projectIds, contractorIds}).then(data => data)
      }
      return null
    },
    select: data => data,
    refetchOnWindowFocus: false
  })
}

export const useCountChecklistsDashboard = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['countChecklistsDashboard', to, from, project1CIds, projectIds, contractorIds],
    queryFn: async () => {
      if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
      return await diagramApi.getCountCheckLists({to, from, project1CIds, projectIds, contractorIds}).then(data => data)
      }
      return null
    },
    select: data => data,
    refetchOnWindowFocus: false
  })
}

export const useCountOrdinanceFinesDashboard = () => {
  const {to, from, project1CIds, projectIds, contractorIds} = useDiagramState()
  return useQuery({
    queryKey: ['countOrdinanceFinesDashboard', to, from, project1CIds, projectIds, contractorIds],
    queryFn: async () => {
      if (to !== undefined && from !== undefined && project1CIds && projectIds && contractorIds){
      return await diagramApi.getCountOrdinanceFines({to, from, project1CIds, projectIds, contractorIds}).then(data => data)
      }
      return null
    },
    select: data => data,
    refetchOnWindowFocus: false
  })
}