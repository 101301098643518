import { DownloadOutlined } from '@ant-design/icons'
import { useIsFetching } from '@tanstack/react-query'
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Divider,
	Form,
	Modal,
	Row,
	Select,
	Space,
	Typography,
	message
} from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useAppSelector } from 'hooks/appReduxHook'
import { FC, useEffect, useState } from 'react'
import { useReportState } from '../model/reports-state'
import {
	useReportExcel,
	useReportIssueAuthors,
	useReportIssueContractors
} from '../api/reports-query'
import { downloadDocument } from 'shared/helpers'
import { TReportForm } from '../model/reports-schema'

const { Title, Text } = Typography
const reportTypes = [
	{ key: 1, title: 'Реестр замечаний Новый', value: 'issues' },
	{ key: 2, title: 'Отчёт по автору Новый', value: 'author' }
]

interface IReportForm {
	type?: 'issues' | 'author'
	projects?: string[]
	contractor?: string
	contract?: string
	owner?: string
	range?: [Dayjs, Dayjs]
}

interface IProps {
	show: boolean
	onClose: () => void
}

const ReportsModal: FC<IProps> = ({ show, onClose }) => {
	const [blocked, setBlocked] = useState(false)
	const [allPeriod, setAllPeriod] = useState(false)
	const [reportForm] = Form.useForm<IReportForm>()
	const formValue = Form.useWatch(value => value, reportForm)
	const reportType = Form.useWatch('type', reportForm)
	const reportProjects = Form.useWatch('projects', reportForm)
	const projects = useAppSelector(state => state.environment.user?.projects)
	const project = useAppSelector(state => state.environment.project)
	const { setFilters, download, onPagination } = useReportState()
	const { data: contractors } = useReportIssueContractors(formValue?.projects)
	const { data: authors } = useReportIssueAuthors(formValue?.projects)
	const { mutate: getReportExcel } = useReportExcel()

	const generateReport = async () => {
		const dto: TReportForm = {
			from: !allPeriod ? dayjs(formValue?.range![0]!).format('YYYY-MM-DD') : null,
			to: !allPeriod ? dayjs(formValue?.range![1]!).format('YYYY-MM-DD') : null,
			contractorId: formValue.contractor,
			projectIds: formValue.projects!,
			ownerId: formValue.owner
		}
		if (download) {
			await getReportExcel(dto!, {
				onSuccess: data => {
					downloadDocument(data.data, 'Отчёт')
					onClose()
				},
				onError: () => message.error('Во время загрузки файла произошла ошибка')
			})
		} else {
			setFilters({ ...dto })
		}
		onClose()
		reportForm.resetFields()
		onPagination(1)
		useReportState.setState({ download: false })
	}

	useEffect(() => {
		reportForm.setFieldValue('contractor', undefined)
		reportForm.setFieldValue('owner', undefined)
	}, [reportProjects])

	useEffect(() => {
		reportForm.setFieldValue('projects', [project.id])
		setAllPeriod(true)
	}, [reportType])
	return (
		<Form form={reportForm} name="reportForm" onFinish={generateReport} disabled={blocked}>
			<Modal
				destroyOnClose
				onCancel={() => {
					onClose()
					reportForm.resetFields()
				}}
				centered
				open={show}
				title="Создать отчет"
				footer={
					<Row justify="space-between">
						<Col>
							<Button
								danger
								onClick={() => {
									setAllPeriod(false)
									reportForm.resetFields()
									useReportState.setState({ download: false })
								}}
							>
								Сбросить
							</Button>
						</Col>
						<Col>
							<Space>
								<Button type="primary" onClick={() => reportForm.submit()}>
									Сформировать
								</Button>
								<Button
									type="primary"
									icon={<DownloadOutlined />}
									onClick={() => {
										useReportState.setState({ download: true })
										reportForm.submit()
									}}
								>
									Скачать
								</Button>
							</Space>
						</Col>
					</Row>
				}
			>
				<Row justify="center">
					<Col
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '.5rem',
							padding: '1rem',
							width: '100%'
						}}
					>
						<Col>
							<Title level={5}>Тип отчёта:</Title>
							<Form.Item
								name="type"
								rules={[
									{
										required: true,
										message: 'Выберете тип отчёта'
									}
								]}
							>
								<Select
									allowClear
									style={{ width: '100%' }}
									options={reportTypes?.map(t => ({
										key: t.key,
										label: t.title,
										value: t.value
									}))}
								/>
							</Form.Item>
						</Col>
						{formValue?.type !== undefined && formValue?.type !== null && (
							<>
								<Col>
									<Title level={5}>Проекты:</Title>
									<Form.Item
										name="projects"
										rules={[
											{
												required: true,
												message: 'Выберете хотя бы один проект'
											}
										]}
									>
										<Select
											mode="multiple"
											showSearch
											maxTagCount="responsive"
											allowClear
											style={{ width: '100%' }}
											onClear={() => reportForm.setFieldValue('contractor', undefined)}
											optionFilterProp="children"
											filterOption={(input, option) =>
												(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
											}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '')
													.toLowerCase()
													.localeCompare((optionB?.label ?? '').toLowerCase())
											}
											options={projects?.map(p => ({
												key: p.id,
												label: p.name,
												value: p.id
											}))}
										/>
									</Form.Item>
								</Col>
								{formValue?.type !== 'author' && (
									<Col>
										<Title level={5}>Контрагент:</Title>
										<Form.Item
											name="contractor"
											rules={[
												{
													required: true,
													message: 'Выберете контрагента'
												}
											]}
										>
											<Select
												disabled={contractors?.length === 0 || formValue?.projects?.length === 0}
												allowClear
												showSearch
												optionFilterProp="children"
												filterOption={(input, option) =>
													(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
												}
												filterSort={(optionA, optionB) =>
													(optionA?.label ?? '')
														.toLowerCase()
														.localeCompare((optionB?.label ?? '').toLowerCase())
												}
												style={{ width: '100%' }}
												options={contractors?.map(p => ({
													key: p.id,
													label: p.name,
													value: p.id
												}))}
											/>
										</Form.Item>
									</Col>
								)}

								{formValue?.type === 'author' && (
									<Col>
										<Title level={5}>Автор:</Title>
										<Form.Item name="owner">
											<Select
												disabled={authors?.length === 0 || formValue?.projects?.length === 0}
												allowClear
												showSearch
												optionFilterProp="children"
												filterOption={(input, option) =>
													(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
												}
												filterSort={(optionA, optionB) =>
													(optionA?.label ?? '')
														.toLowerCase()
														.localeCompare((optionB?.label ?? '').toLowerCase())
												}
												style={{ width: '100%' }}
												options={authors?.map(p => ({
													key: p.ownerId,
													label: p.name,
													value: p.ownerId
												}))}
											/>
										</Form.Item>
									</Col>
								)}
								<Col>
									<Title level={5}>Период:</Title>
									<Form.Item
										name="range"
										rules={[
											{
												required: !allPeriod,
												message: 'Укажите период'
											}
										]}
									>
										<DatePicker.RangePicker
											disabled={formValue?.type === null || allPeriod}
											format="DD.MM.YYYY"
											style={{ width: '100%' }}
										/>
									</Form.Item>
								</Col>
								<Row justify="end">
									<Text style={{ marginRight: '1rem' }}>За всё время</Text>
									<Checkbox
										checked={allPeriod}
										onChange={e => setAllPeriod(e.target.checked)}
										disabled={formValue?.type === null}
									></Checkbox>
								</Row>

								<Divider />
							</>
						)}
					</Col>
				</Row>
			</Modal>
		</Form>
	)
}

export default ReportsModal
