import { CloseCircleTwoTone } from '@ant-design/icons'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Col, Modal, notification, Row } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { FormInstance, Select } from 'antd/lib'
import { AxiosError } from 'axios'
import { IErrorDetail } from 'interfaces/IBase'
import { useWbsTaskState } from 'pages/wbs-tasks-page/model/wbs-tasks-state'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import { FC } from 'react'
import { WBS_TASK_STATUS } from 'shared/constants/wbs-task-types'
import { wbsTaskCardApi } from '../api/wbs-task-card-api'
import { useWbsTaskCard } from '../api/wbs-task-card-queries'
import { useWbsTaskCardState } from '../model/wbs-task-card-state'

interface IProps {
	disabled?: boolean
	form: FormInstance
	isLoading: boolean
	options?: DefaultOptionType[]
	tagRender?: (props: CustomTagProps) => JSX.Element
	assigned?: string[]
	setAssigned?: (assign: string[]) => void
	onAssignedSelect?: (assign: string) => void
	onAssignedDeselect?: (assign: string) => void
}

export const WbsTaskModalAssign: FC<IProps> = props => {
	const {
		disabled,
		form,
		isLoading,
		options,
		tagRender,
		assigned,
		setAssigned,
		onAssignedSelect,
		onAssignedDeselect
	} = props
	const { openModalAssign, setCloseModalAssign } = useWbsTaskCardState()
	const { currentTaskId } = useWbsTaskState()
	const queryClient = useQueryClient()
	const { data, isFetching } = useWbsTaskCard()

	const { mutate: mutateAssign, isLoading: isLoadingAssign } = useMutation({
		mutationKey: ['wbsTask', 'attachments'],
		mutationFn: async ({
			wbsTaskId,
			dto,
			type
		}: {
			wbsTaskId: string
			dto: string[]
			type: 'design' | 'economy'
		}) =>
			await (type === 'design'
				? wbsTaskCardApi.updateAssignDesign
				: wbsTaskCardApi.updateAssignEconomy)(wbsTaskId!, dto),
		onSuccess: () => {
			queryClient.invalidateQueries(['wbsTaskCard'])
			queryClient.invalidateQueries(['wbsTasks'])
			setCloseModalAssign()
		},
		onError: (error: AxiosError<IErrorDetail>) => {
			notification.error({
				message: 'Произошла ошибка',
				description: error.response?.data?.detail,
				placement: 'topLeft'
			})
		}
	})

	const onSave = () => {
		mutateAssign({
			wbsTaskId: currentTaskId!,
			dto: assigned!,
			type: data?.wbsTaskStatus.id === WBS_TASK_STATUS.docs ? 'design' : 'economy'
		})
		form.validateFields()
	}

	const onClose = () => {
		setCloseModalAssign()
	}

	return (
		<Modal
			destroyOnClose
			onCancel={onClose}
			centered
			open={openModalAssign}
			title="Выбрать ответственных"
			width="30vw"
			footer={
				<Row justify="end">
					<Col>
						<Button onClick={onClose} style={{ marginRight: 5 }}>
							Отмена
						</Button>
						<Button
							type="primary"
							onClick={() => {
								onSave()
							}}
						>
							Сохранить
						</Button>
					</Col>
				</Row>
			}
		>
			<Row justify="center" align="middle" wrap={false}>
				<Col
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '.5rem',
						padding: '1rem',
						width: '100%'
					}}
				>
					<Select
						maxTagCount={1}
						mode={'multiple'}
						tagRender={tagRender ? tagRender : undefined}
						showSearch={true}
						optionFilterProp={'children'}
						filterOption={(input, option) =>
							(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
						}
						filterSort={(optionA, optionB) =>
							(optionA?.label ?? '')
								.toLowerCase()
								.localeCompare((optionB?.label ?? '').toLowerCase())
						}
						loading={isLoading}
						disabled={disabled || options?.length === 0}
						options={options?.map(o => ({
							key: o.id,
							value: o.id,
							label: o.name
						}))}
						allowClear={{
							clearIcon: (
								<CloseCircleTwoTone twoToneColor="#00000040" onClick={() => setAssigned!([])} />
							)
						}}
						onSelect={onAssignedSelect}
						onDeselect={onAssignedDeselect}
						value={assigned}
					/>
				</Col>
			</Row>
		</Modal>
	)
}
