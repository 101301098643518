// import { API } from "services/ApiService"
// import { IContractor, IIssue, IIssueSubType, IProject, IProject1C } from "../model/diagrams-types"


// export const diagramApi = {
//   async getProjects1C() {
//     return await API<IProject1C[]>({
//       url: `/v1/diagrams/projects1C`,
//       method: 'get'
//     }).then(response => response.data)
//   },

//   async getProjects() {
//     return await API<IProject[]>({
//       url: `/v1/diagrams/projects`,
//       method: 'get'
//     }).then((response) => response.data)
//   },

//   async getContractors() {
//     return await API<IContractor[]>({
//       url: '/v1/diagrams/contractors',
//       method: 'get'
//     }).then(response => response.data)
//   },

//   async getCountContractors(params?: any) {
//     return await API({
//       url: '/v1/diagrams/countContractors',
//       method: 'get',
//       params: params
//     }).then(response => response.data)
//   },

//   async getIssues(params?: any) {
//     return await API<IIssue[]>({
//       url: "/v1/diagrams/Issues",
//       method: "get",
//       params
//     }).then((response) => response.data);
//   },

//   async getIssueSubTypes(params: { contractorId: string }) {
//     return await API<IIssueSubType[]>({
//       url: "/v1/diagrams/IssueSubTypes",
//       method: "get",
//       params: { contractorId }
//     }).then((response) => response.data);
//   },

//   async getOrdinances() {
//     return await API<IOrdinance[]>({
//       url: "/v1/diagrams/Ordinances",
//       method: "get",
//     }).then((response) => response.data);
//   },

//   async getMyFiles() {
//     return await API<IFile[]>({
//       url: "/v1/diagrams/MyFiles",
//       method: "get",
//     }).then((response) => response.data);
//   },

//   async getContractorsRating() {
//     return await API<IContractorRating[]>({
//       url: "/v1/diagrams/contractorsRating",
//       method: "get",
//     }).then((response) => response.data);
//   },

//   async getCountContainers() {
//     return await API<IContainerCount>({
//       url: "/v1/diagrams/countContainers",
//       method: "get",
//     }).then((response) => response.data);
//   },

//   async getCountIssues() {
//     return await API<IIssueCount>({
//       url: "/v1/diagrams/countIssues",
//       method: "get",
//     }).then((response) => response.data);
//   },

//   async getCountOrdinances() {
//     return await API<IOrdinanceCount>({
//       url: "/v1/diagrams/countOrdinances",
//       method: "get",
//     }).then((response) => response.data);
//   },

//   async getCountOrdinanceFines() {
//     return await API<IOrdinanceFineCount>({
//       url: "/v1/diagrams/countOrdinanceFines",
//       method: "get",
//     }).then((response) => response.data);
//   },

//   async getCountCheckLists() {
//     return await API<ICheckListCount>({
//       url: "/v1/diagrams/countCheckLists",
//       method: "get",
//     }).then((response) => response.data);
//   },
// }













import { API } from "services/ApiService";
import { ICheckListCount, IContainerCount, IContractor, IContractorIdFilter, IContractorRating, IDateRangeFilter, IIssueCount, IIssueSubTypes, IOrdinanceCount, IOrdinanceFineCount, IProject, IProject1C, IProjectFilter } from "../model/diagrams-types";

export const diagramApi = {
  async getProjects1C(projectIds?: string[], contractorIds?: string[]) {
    return await API<IProject1C[]>({
      url: `/v1/diagrams/projects1C`,
      method: 'get',
      params: {projectIds: projectIds, contractorIds: contractorIds}
    }).then(response => response.data)
  },

  async getProjects(project1CIds?: string[], contractorIds?: string[]) {
    return await API<IProject[]>({
      url: `/v1/diagrams/projects`,
      method: 'get',
      params: {project1CIds: project1CIds, contractorIds: contractorIds}
    }).then((response) => response.data)
  },

  async getContractors(projectIds?: string[], project1CIds?: string[]) {
    return await API<IContractor[]>({
      url: '/v1/diagrams/contractors',
      method: 'get',
      params: {projectIds: projectIds, project1CIds: project1CIds}
    }).then(response => response.data)
  },

  async getCountContractors(params?: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API({
      url: '/v1/diagrams/countContractors',
      method: 'get',
      params
    }).then(response => response.data)
  },

  async getContractorsRating(params: IDateRangeFilter) {
    return await API<IContractorRating[]>({
      url: "/v1/diagrams/contractorsRating",
      method: "get",
      params
    }).then((response) => response.data)
  },

  async getCountContainers(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<IContainerCount>({
      url: "/v1/diagrams/countContainers",
      method: "get",
      params
    }).then((response) => response.data)
  },

  async getCountIssues(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<IIssueCount>({
      url: "/v1/diagrams/countIssues",
      method: "get",
      params
    }).then((response) => response.data)
  },

  async getCountOrdinances(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<IOrdinanceCount>({
      url: "/v1/diagrams/countOrdinances",
      method: "get",
      params
    }).then((response) => response.data)
  },

  async getCountOrdinanceFines(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<IOrdinanceFineCount>({
      url: "/v1/diagrams/countOrdinanceFines",
      method: "get",
      params
    }).then((response) => response.data)
  },

  async getCountCheckLists(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<ICheckListCount>({
      url: "/v1/diagrams/countCheckLists",
      method: "get",
      params
    }).then((response) => response.data);
  },

  async getIssueSubTypes(params: IDateRangeFilter & IProjectFilter & IContractorIdFilter) {
    return await API<IIssueSubTypes[]>({
      url: "/v1/diagrams/issueSubTypes",
      method: "get",
      params
    }).then((response) => response.data);
  }
};
