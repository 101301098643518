import { create } from "zustand";

interface IDiagramState {
    to: string | null
    from: string | null
    project1CIds: string[] | null
    projectIds: string[] | null
    contractorIds: string[] | null
    setFilters: ({to, from, project1CIds, projectIds, contractorIds} : {to?: string | null , from?: string | null, project1CIds?: string[] | null, projectIds?: string[] | null, contractorIds?: string[] | null}) => void
}

export const useDiagramState = create<IDiagramState>((set, get) => ({
    to: null,
    from: null,
    project1CIds: null,
    projectIds: null,
    contractorIds: null,
    setFilters: (({to, from, project1CIds, projectIds, contractorIds}) => set(() => (({to, from, project1CIds, projectIds, contractorIds}))))
}))