import { API } from "services/ApiService"
import { TReport, TReportForm } from "../model/reports-schema"
import { IPagination } from "interfaces/IPagination"
import { PAGE_LIMIT } from "shared/constants"

export const reportApi = {
    async get(filters: TReportForm, currentPage: number = 1) {
        return await API<IPagination<TReport[]>>({
            url: `/v1/reports/issuesDataReport`,
            method: 'get',
                        params: {
                            limit: PAGE_LIMIT,
                            offset: currentPage === 1 ? 0 : currentPage * PAGE_LIMIT - PAGE_LIMIT,
                            projectIds: filters.projectIds, 
                            from: filters.from, 
                            to: filters.to, 
                            contractorId: filters.contractorId, 
                            ownerId: filters.ownerId
                        }
        }).then(response => response.data)
    },
    async exportToExcel(filters: any) {
		return await API({
			url: '/v1/export/exportIssuesDataToExcel',
			method: 'get',
			params: { ...filters },
			responseType: 'blob'
		}).then(async response => response)
	}
}