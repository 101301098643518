import { CheckOutlined, DeleteOutlined, FilterOutlined, LoadingOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import LoaderSpinner from 'UI/loaderSpinner'
import {
	Button,
	Col,
	ConfigProvider,
	Empty,
	Input,
	Popconfirm,
	Row,
	Table,
	Tooltip,
	message
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { useChecklistTemplateLists } from 'hooks/useChecklistTemplateList'
import { IErrorDetail } from 'interfaces/IBase'
import { IChecklistFilters } from 'interfaces/IChecklist'
import { IPermission } from 'interfaces/IPermissions'
import { ITemplate } from 'interfaces/ITemplate'
import { FC, useEffect, useState } from 'react'
import { deleteTemplate, getCheckListTemplates } from 'services/TemplatesService'
import { PERMISSIONS } from 'shared/constants'
import { useCheckPermissions } from 'shared/useCheckPermissions'
import { setPageTitle } from 'store/rootSlice'
import {
	setCurrentPage,
	setTemplates,
	setTemplatesFilters,
	setTemplatesSort,
	setViewerStatus
} from 'store/templatesSlice'
import { useChecklistTemplateCardState } from 'widgets/checklist-template-card/model/checklist-template-card-state'
import { ChecklistTemplateCard } from 'widgets/checklist-template-card/ui/ChecklistTemplateCard'
import ChecklistFilters from '../ChecklistFilters'

const { Search } = Input

interface IProps {
	// checkPermissions: (currentPermits: string[]) => boolean
	checkViewPermissions: (type: 'checklist' | 'template') => 0 | 1 | 2 | null
}

const Templates: FC<IProps> = ({
	// checkPermissions,
	checkViewPermissions
}) => {
	const checkPermissions = useCheckPermissions()
	const dispatch = useAppDispatch()
	const templatesChecklistState = useAppSelector(state => state.templates.listState)
	const templatesFilters = useAppSelector(state => state.templates.filters)
	const queryClient = useQueryClient()
	const { data: initialTemplates, isFetching } = useChecklistTemplateLists()
	const { user: currentUser, permissions } = useAppSelector(state => state.environment)
	const { viewerStatus } = useAppSelector(state => state.templates.data)
	const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
	const [editedTemplate, setEditedTemplate] = useState<ITemplate | null>(null)
	const [openFilters, setOpenFilters] = useState<boolean>(false)
	// Легенда: null - нет прав, 0 - УКС НДК, 1 - УК КС, 2 - оба типа

	const workPackages = useAppSelector(state => state.environment.workPackages)

	const { project } = useAppSelector(state => state.environment)
	const canDelete = checkPermissions([PERMISSIONS.CheckListDeleted])
	const { setCurrentTemplateId, showToggle, setCurrentTemplateType } =
		useChecklistTemplateCardState()

	const containers: ColumnsType<ITemplate> = [
		{
			key: 'id',
			dataIndex: 'type',
			align: 'center',
			title: 'Тип',
			width: 120,
			render: (_, record) => (record.templateType === 0 ? 'УКС НДК' : 'УК КС')
		},
		{
			key: 'cipher',
			dataIndex: 'cipher',
			align: 'center',
			title: 'Шифр',
			width: 120,
			render: (_, record) => record.cipher
		},
		{
			key: 'workPackageId',
			dataIndex: 'workPackageId',
			align: 'center',
			title: 'Пакет работ',
			width: 120,
			render: (_, record) => workPackages?.find(wp => wp.id === record.workPackageId)?.name
		},
		{
			key: 'id',
			dataIndex: 'order',
			align: 'center',
			title: 'Порядковый номер',
			width: 120
		},
		{
			key: 'name',
			dataIndex: 'name',
			title: 'Название',
			width: 250,
			sorter: true,
			// sorter: (a, b) => a.title.localeCompare(b.title),
			render: (_, record) => (
				<span
					style={{
						textOverflow: 'ellipsis',
						overflow: 'hidden'
					}}
				>
					{record.title}
				</span>
			)
		},
		{
			key: 'createdDate',
			title: 'Дата создания',
			width: 200,
			showSorterTooltip: false,
			render: (_, record) => dayjs(record.createdAt).format('DD.MM.YYYY HH:mm')
		},
		{
			key: 'author',
			dataIndex: 'author',
			title: 'Владелец',
			width: 300,
			sorter: true,
			render: (_, record) => (record.owner === null ? 'Не указан' : record.owner.name)
		},
		// {
		// 	dataIndex: 'plannedDate',
		// 	title: 'Плановая дата передачи системы',
		// 	width: 200,
		// 	showSorterTooltip: false,
		// 	render: value => (value ? dayjs(value).format('DD.MM.YYYY') : '-')
		// },
		{
			key: 'isActive',
			title: 'Активный',
			align: 'center',
			width: 100,
			showSorterTooltip: false,
			render: (_, record) =>
				record.isActive && <CheckOutlined style={{ color: 'green' }} rev={undefined} />
		},
		{
			key: 'isCommon',
			title: 'Общий',
			align: 'center',
			width: 100,
			showSorterTooltip: false,
			render: (_, record) =>
				record.isCommon && <CheckOutlined style={{ color: 'green' }} rev={undefined} />
		},
		{
			key: 'updatedAt',
			title: 'Дата обновления',
			width: 200,
			showSorterTooltip: false,
			render: (_, record) => (record.updatedAt ? dayjs(record.updatedAt).format('DD.MM.YYYY') : '-')
		},
		{
			width: currentUser?.isAdmin || canDelete ? 80 : 1,
			align: 'center',
			render: (_, record) =>
				(currentUser?.isAdmin || canDelete) && (
					<Popconfirm
						title="Удалить?"
						placement="topRight"
						onConfirm={async () =>
							await deleteTemplate(record!?.id!)
								.then(async () => {
									message.success(`Шаблон ${record!?.title!} успешно удалён`)
									queryClient.invalidateQueries(['templates'])
									// await getCheckListTemplates({
									// 	templateType: viewerStatus,
									// 	filters: templatesFilters
									// })
									// 	.then(data => {
									// 		dispatch(setTemplates(data))
									// 	})
									// 	.catch(error => message.error(error.message))
								})
								.catch((error: AxiosError) => {
									const data = error!?.response!?.data! as IErrorDetail
									message.error(data.detail)
								})
						}
						okButtonProps={{ danger: true }}
						okText="Да"
						cancelText="Отмена"
					>
						<Button icon={<DeleteOutlined />} danger />
					</Popconfirm>
				),
			fixed: 'right'
		}
	]

	const handleRowDoubleClick = (record: ITemplate) => {
		setCurrentTemplateId(record.id)
		setCurrentTemplateType(record.templateType)
	}

	const onCloseTemplateDrawer = () => {
		setDrawerVisible(false)
		setEditedTemplate(null)
	}

	const pagination = (page: number, pageSize: number) => {
		dispatch(setCurrentPage(page))
	}
	const onSearch = (searchValue: string | null) => {
		const filters = { ...templatesFilters, title: searchValue! }
		dispatch(setTemplatesFilters({ filters }))
		dispatch(setCurrentPage(1))
	}

	useEffect(() => {
		dispatch(setPageTitle({ mainTitle: 'Шаблоны' }))
	}, [])

	useEffect(() => {
		if (viewerStatus !== null) {
			dispatch(setCurrentPage(1))
			getCheckListTemplates({
				templateType: viewerStatus,
				filters: templatesFilters
			})
				.then(data => {
					dispatch(setTemplates(data))
				})
				.catch(error => message.error(error.message))
		}
	}, [viewerStatus, project])

	useEffect(() => {
		// Права на просмотр шаблонов
		if (currentUser?.isAdmin) {
			dispatch(setViewerStatus(2))
		} else {
			dispatch(setViewerStatus(checkViewPermissions('template')))
		}
	}, [])

	const onSetFilters = (filters: IChecklistFilters, show: boolean) => {
		if (filters) {
			dispatch(
				setTemplatesFilters({
					filters: {
						...templatesFilters,
						templateType: filters?.templateType,
						status: filters?.status,
						ownerId: filters?.ownerId,
						createdAt: filters?.createdAt,
						isActive: filters?.isActive
					}
				})
			)
			if (show) {
				setOpenFilters(false)
			}
		}
		dispatch(setCurrentPage(1))
	}

	return (
		<div className="checklists__block">
			{isFetching && <LoaderSpinner />}
			{}

			<ChecklistFilters
				open={openFilters}
				parentPage={'templates'}
				onClose={() => setOpenFilters(false)}
				onFilters={onSetFilters}
			/>

			{/* <TemplateDrawer
				open={drawerVisible}
				onClose={onCloseTemplateDrawer}
				editedTemplate={editedTemplate}
				creatorStatus={creatorStatus}
				// checkPermissions={checkPermissions}
			/> */}
			<ChecklistTemplateCard />

			<Row justify="space-between" style={{ marginBottom: '1rem' }}>
				<Col>
					<Row gutter={8}>
						<Col>
							<Tooltip title="Фильтры">
								<Button
									type={
										Object.entries(templatesFilters).some(
											x => x[0] !== 'search' && x[0] !== 'title' && typeof x[1] !== 'undefined'
										)
											? 'primary'
											: 'default'
									}
									size="middle"
									onClick={() => setOpenFilters(true)}
									icon={<FilterOutlined />}
								/>
							</Tooltip>
						</Col>
						<Col>
							<Search
								allowClear
								placeholder="Поиск..."
								defaultValue={templatesFilters.title}
								onSearch={value => onSearch(value)}
								style={{ width: 300 }}
							/>
						</Col>
					</Row>
				</Col>
				<Col>
					<Row gutter={8}>
						<Col>
							<Button
								type="primary"
								size="middle"
								onClick={() => showToggle(true)}
								disabled={
									!checkPermissions([
										PERMISSIONS.ChecklistsTemplatesEditUksNdc,
										PERMISSIONS.ChecklistsTemplatesEditUkKc
									])
								}
							>
								Создать шаблон чек-листа
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>

			<ConfigProvider
				renderEmpty={() => (
					<Empty description="Нет ни одного чеклиста" image={Empty.PRESENTED_IMAGE_SIMPLE} />
				)}
			>
				<Table
					loading={{ indicator: <LoadingOutlined spin />, spinning: isFetching }}
					bordered
					rowKey={row => row.id}
					columns={containers}
					scroll={{ y: '74vh', x: '10%' }}
					onChange={(pagination, filters, FilterValue, sorter) => {
						const titleDirection = sortVariation(
							(FilterValue as any).columnKey !== 'name' ? undefined : (FilterValue as any).order
						)
						const ownerDirection = sortVariation(
							(FilterValue as any).columnKey !== 'author' ? undefined : (FilterValue as any).order
						)
						dispatch(
							setTemplatesSort({
								titleSort: titleDirection,
								ownerSort: ownerDirection
							})
						)
					}}
					dataSource={
						initialTemplates
							? viewerStatus === 2
								? initialTemplates
								: initialTemplates.filter(
										(template: ITemplate) => template.templateType === viewerStatus
								  )
							: []
					}
					pagination={{
						current: templatesChecklistState.currentPage,
						defaultPageSize: 50,
						total: templatesChecklistState.totalItems,
						hideOnSinglePage: false,
						showSizeChanger: false,
						onChange: pagination
					}}
					onRow={record => ({
						onDoubleClick: () => {
							handleRowDoubleClick(record)
						}
					})}
				/>
			</ConfigProvider>
		</div>
	)
}

const sortVariation = (order: string) => {
	switch (order) {
		case 'ascend':
			return 'ASC'
		case 'descend':
			return 'DESC'
		default:
			return undefined
	}
}

export default Templates
