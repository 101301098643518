import { useQuery } from '@tanstack/react-query'
import { Modal } from 'antd'
import { AxiosError } from 'axios'
import { useAppDispatch, useAppSelector } from 'hooks/appReduxHook'
import { IErrorDetail } from 'interfaces/IBase'
import { useLocalEstimateState } from 'pages/unmodelPage/localEstimate/model/localEstimateState'
import { useWbsContainerState } from 'pages/unmodelPage/wbsContainer'
import { useParams } from 'react-router-dom'
import { TEstimateType } from 'shared/schema'
import { setPageTitle } from 'store/rootSlice'
import { useWbsStatusModalState } from 'widgets/wbs'
import { adjustmentApi } from '..'
import { useAdjustmentState } from './adjustment-state'

export const useAdjustmentQuery = (type: TEstimateType) => {
	const { id: containerId } = useParams()
	const { setContainer, setContainerType } = useAdjustmentState()
	const dispatch = useAppDispatch()
	return useQuery({
		queryKey: ['adjustmentContainer', containerId],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			const container = await adjustmentApi
				.get(containerId!, type)
				.then(data => data)
				.catch((err: AxiosError<IErrorDetail>) =>
					Modal.warning({ title: 'Произошла ошибка', content: err.response?.data.detail ?? '', onOk: () => window.location.href = `/` })
				)
			if (!(container instanceof AxiosError)) {
				useLocalEstimateState.setState({ container })
				useWbsStatusModalState.setState({ container })
				useWbsContainerState.setState({container})
				setContainer(container)
				setContainerType(type)
				dispatch(
					setPageTitle({
						mainTitle: type === 'local' ? 'Список Локальных смет' : 'Список ВОР',
						subTitle: `Было / Стало: ${container?.name}, Вид раздела: ${container?.typeSections?.name}`
					})
				)
			}
			return container
		},
		cacheTime: 0,
		enabled: typeof containerId !== 'undefined',
		select: data => data
	})
}

export const useAdjustmentSectionsQuery = (type: TEstimateType, containerId?: string) => {
	const { container, setSections } = useAdjustmentState()
	return useQuery({
		queryKey: ['adjustment', container?.id],
		refetchOnWindowFocus: false,
		queryFn: async () => {
			const data = await adjustmentApi.getSections(container?.id!, type).then(data => data)
			setSections(data)
			return data
		},
		enabled: typeof container?.id !== 'undefined',
		cacheTime: 0,
		select: data => data
	})
}

export const useAdjustmentPotentialRedactors = () => {
	const { project } = useAppSelector(state => state.environment)
	return useQuery({
		queryKey: ['adjustmentPotentialRedactors', project],
		queryFn: async () => {
			if (project) {
				return await adjustmentApi.getPotentialRedactors()
			}
			return null
		},
		refetchOnWindowFocus: false
	})
}
